import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { SharedService } from 'src/app/shared.service';
import { UserInterface } from '../../../interfaces/user';
import { ReferenceVariableInterface } from '../../../interfaces/bkd/reference';
import { LecturerTeachingMaterialWritingInterface } from '../../../interfaces/bkd/lecturer-teaching-material-writing';
import { AcademicCourseInterface } from '../../../interfaces/bkd/academic-course';
import { ResponseInterface } from '../../../interfaces/response';

@Component({
  selector: 'app-lecturer-teaching-material-writings',
  templateUrl: './lecturer-teaching-material-writings.component.html',
  styleUrls: ['./lecturer-teaching-material-writings.component.css']
})
export class LecturerTeachingMaterialWritingsComponent implements OnInit {

  userName:string;
  isInitializing:boolean = true;

  selectedAcademicYearID:string = '';
  selectedSemesterID:string = '';

  lecturerTeachingMaterialWritingList:LecturerTeachingMaterialWritingInterface[];
  filteredLecturerTeachingMaterialWritingList:LecturerTeachingMaterialWritingInterface[];
  sortedLecturerTeachingMaterialWritingList:LecturerTeachingMaterialWritingInterface[];

  errorMessage:string = '';
  isSubmitting:boolean = false;
  hasSuccessfullySubmitted:boolean = false;

  userList:UserInterface[];
  academicCourseList:AcademicCourseInterface[];
  teachingMaterialReferenceList:ReferenceVariableInterface[];
  teachingMaterialWorkloadReferenceList:ReferenceVariableInterface[];
  teachingMaterialWriterReferenceList:ReferenceVariableInterface[];
  academicYearReferenceList:ReferenceVariableInterface[];
  semesterReferenceList:ReferenceVariableInterface[];

  lecturer:LecturerTeachingMaterialWritingInterface;
  lecturerID:number;
  lecturerUserID:number;
  lecturerAcademicCourseID:number;
  lecturerRefTeachingMaterialID:number;
  lecturerRefTeachingMaterialWorkloadID:number;
  lecturerRefTeachingMaterialWriterID:number;
  lecturerRefAcademicYearID:number;
  lecturerRefSemesterID:number;
  lecturerModuleCount:number;
  lecturerWorkload:number;

  constructor(private service:SharedService, private router:Router) { }

  ngOnInit(): void {
    this.checkCurrentUser();
  }

  checkCurrentUser() {
    this.service.getCurrentUser().subscribe((user:UserInterface)=>{
      this.userName = user.name;
      this.service.checkCurrentUserPermission(user);
      this.isInitializing = false;

      this.getAllLecturerTeachingMaterialWritings();
      this.getAllLecturers();
      this.getAllAcademicCourses();
      this.getAllTeachingMaterialReferences();
      this.getAllTeachingMaterialWorkloadReferences();
      this.getAllTeachingMaterialWriterReferences();
      this.getAllAcademicYearReferences();
      this.getAllSemesterReferences();
    }, err => {
      this.isInitializing = false;
      this.router.navigate(['/login']);
    });
  }

  getAllLecturerTeachingMaterialWritings() {
    this.service.getAllLecturerTeachingMaterialWritings().subscribe((response:LecturerTeachingMaterialWritingInterface[])=>{
      this.lecturerTeachingMaterialWritingList = response;

      this.filterList();
    }, err => {
      alert(err.error.message);
    });
  }

  filterList() {
    var key1 = this.selectedAcademicYearID;
    var key2 = this.selectedSemesterID;

    if (key1 == '' && key2 == '')
    {
      this.filteredLecturerTeachingMaterialWritingList = this.lecturerTeachingMaterialWritingList;
    }
    else if ((key1 != '' && key2 == '') || (key1 == '' && key2 != ''))
    {
      this.filteredLecturerTeachingMaterialWritingList = this.lecturerTeachingMaterialWritingList.filter(function(param)
      {
        return (param.ref_academic_year.id.toString() == key1) || (param.ref_semester.id.toString() == key2)
      });
    }
    else
    {
      this.filteredLecturerTeachingMaterialWritingList = this.lecturerTeachingMaterialWritingList.filter(function(param)
      {
        return (param.ref_academic_year.id.toString() == key1) && (param.ref_semester.id.toString() == key2)
      });
    }

    this.sortedLecturerTeachingMaterialWritingList = this.filteredLecturerTeachingMaterialWritingList.slice();
  }

  sortList(sort: Sort) {
    let data = this.lecturerTeachingMaterialWritingList;
    
    if (!sort.active || sort.direction === '') {
      this.sortedLecturerTeachingMaterialWritingList = data;
      return;
    }

    this.sortedLecturerTeachingMaterialWritingList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'user_academic_role': return compare(a.user.academic_role, b.user.academic_role, isAsc);
        case 'user_academic_major': return compare(a.user.academic_major, b.user.academic_major, isAsc);
        case 'user_code': return compare(a.user.code, b.user.code, isAsc);
        case 'user_name': return compare(a.user.name, b.user.name, isAsc);
        case 'value': return compare(a.value, b.value, isAsc);
        default: return 0;
      }
    });
  }

  getAllLecturers() {
    this.service.getAllLecturers().subscribe((response:UserInterface[])=>{
      this.userList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllAcademicCourses() {
    this.service.getAllAcademicCourses().subscribe((response:AcademicCourseInterface[])=>{
      this.academicCourseList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllTeachingMaterialReferences() {
    this.service.getAllTeachingMaterialReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.teachingMaterialReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllTeachingMaterialWorkloadReferences() {
    this.service.getAllTeachingMaterialWorkloadReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.teachingMaterialWorkloadReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllTeachingMaterialWriterReferences() {
    this.service.getAllTeachingMaterialWriterReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.teachingMaterialWriterReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllAcademicYearReferences() {
    this.service.getAllAcademicYearReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.academicYearReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllSemesterReferences() {
    this.service.getAllSemesterReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.semesterReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  showModal(item?:LecturerTeachingMaterialWritingInterface)
  {
    this.hasSuccessfullySubmitted = false;
    this.errorMessage = null;

    if (item != null && item != undefined)
    {
      this.lecturer = item;
      this.lecturerID = this.lecturer.id;
      this.lecturerUserID = this.lecturer.user.id;
      this.lecturerAcademicCourseID = this.lecturer.academic_course.id;
      this.lecturerRefTeachingMaterialID = this.lecturer.ref_teaching_material.id;
      this.lecturerRefTeachingMaterialWorkloadID = this.lecturer.ref_teaching_material_workload.id;
      this.lecturerRefTeachingMaterialWriterID = this.lecturer.ref_teaching_material_writer.id;
      this.lecturerRefAcademicYearID = this.lecturer.ref_academic_year.id;
      this.lecturerRefSemesterID = this.lecturer.ref_semester.id;
      this.lecturerModuleCount = this.lecturer.module_count;
      this.lecturerWorkload = this.lecturer.workload;
    }
  }

  closeModal()
  {
    this.lecturer = null;
    this.lecturerID = null;
    this.lecturerUserID = null;
    this.lecturerAcademicCourseID = null;
    this.lecturerRefTeachingMaterialID = null;
    this.lecturerRefTeachingMaterialWorkloadID = null;
    this.lecturerRefTeachingMaterialWriterID = null;
    this.lecturerRefAcademicYearID = null;
    this.lecturerRefSemesterID = null;
    this.lecturerModuleCount = null;
    this.lecturerWorkload = null;
  }

  submit() {
    var param = {
      user_id : this.lecturerUserID,
      academic_course_id : this.lecturerAcademicCourseID,
      ref_teaching_material_id : this.lecturerRefTeachingMaterialID,
      ref_teaching_material_workload_id : this.lecturerRefTeachingMaterialWorkloadID,
      ref_teaching_material_writer_id : this.lecturerRefTeachingMaterialWriterID,
      ref_academic_year_id : this.lecturerRefAcademicYearID,
      ref_semester_id : this.lecturerRefSemesterID,
      module_count : this.lecturerModuleCount,
      workload : this.lecturerWorkload
    }

    this.isSubmitting = true;

    var id = null;
    if (this.lecturer != null && this.lecturer != undefined)
    {
      id = this.lecturerID;
    }
    
    this.service.postBKDData('lecturer_teaching_material_writings', param, id).subscribe((response:ResponseInterface)=>{
      if (response.message == 'ok')
      {
        this.hasSuccessfullySubmitted = true;
        this.getAllLecturerTeachingMaterialWritings();
      }
      
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }

  delete() {
    this.isSubmitting = true;

    var id = this.lecturerID;
    
    this.service.deleteBKDData('lecturer_teaching_material_writings', id).subscribe((response:ResponseInterface)=>{
      if (response.message == 'ok')
      {
        this.hasSuccessfullySubmitted = true;
        this.getAllLecturerTeachingMaterialWritings();
      }
      
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
