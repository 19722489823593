import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { SharedService } from 'src/app/shared.service';
import { UserInterface } from '../../../interfaces/user';
import { ReferenceVariableInterface } from '../../../interfaces/bkd/reference';
import { LecturerSummaryInterface } from '../../../interfaces/bkd/lecturer-summary';

@Component({
  selector: 'app-summaries',
  templateUrl: './summaries.component.html',
  styleUrls: ['./summaries.component.css']
})
export class SummariesComponent implements OnInit {

  userName:string;
  isInitializing:boolean = true;

  selectedAcademicYearID:string = '';
  selectedSemesterID:string = '';

  lecturerSummaryList:LecturerSummaryInterface[];
  sortedLecturerSummaryList:LecturerSummaryInterface[];

  academicYearReferenceList:ReferenceVariableInterface[];
  semesterReferenceList:ReferenceVariableInterface[];

  constructor(private service:SharedService, private router:Router) { }

  ngOnInit(): void {
    this.checkCurrentUser();
  }

  checkCurrentUser() {
    this.service.getCurrentUser().subscribe((user:UserInterface)=>{
      this.userName = user.name;
      this.service.checkCurrentUserPermission(user);
      this.isInitializing = false;

      this.getAllLecturerWorkloadSummaries();
      this.getAllAcademicYearReferences();
      this.getAllSemesterReferences();
    }, err => {
      this.isInitializing = false;
      this.router.navigate(['/login']);
    });
  }

  getAllLecturerWorkloadSummaries() {
    this.service.getAllLecturerWorkloadSummaries(this.selectedAcademicYearID, this.selectedSemesterID).subscribe((response:LecturerSummaryInterface[])=>{
      this.lecturerSummaryList = response;
      this.sortedLecturerSummaryList = this.lecturerSummaryList.slice();
    }, err => {
      alert(err.error.message);
    });
  }

  sortList(sort: Sort) {
    let data = this.lecturerSummaryList;
    
    if (!sort.active || sort.direction === '') {
      this.sortedLecturerSummaryList = data;
      return;
    }

    this.sortedLecturerSummaryList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'user_academic_role': return compare(a.academic_role, b.academic_role, isAsc);
        case 'user_academic_major': return compare(a.academic_major, b.academic_major, isAsc);
        case 'user_code': return compare(a.code, b.code, isAsc);
        case 'user_name': return compare(a.name, b.name, isAsc);
        case 'value': return compare(a.total_value, b.total_value, isAsc);
        default: return 0;
      }
    });
  }

  getAllAcademicYearReferences() {
    this.service.getAllAcademicYearReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.academicYearReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllSemesterReferences() {
    this.service.getAllSemesterReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.semesterReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
