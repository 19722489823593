<mat-sidenav-container>
  <mat-sidenav *ngIf="hasLoggedIn" mode="over" fixedInViewport="true" [(opened)]="isSideNavOpened">
    <mat-nav-list>
      <a mat-list-item (click)="isSideNavOpened = false" routerLink="profile">Profile</a>
      
      <mat-list-item (click)="hasExpandedHRD = !hasExpandedHRD">
				<span>HRD</span>
			</mat-list-item>
      <div *ngIf="hasExpandedHRD" class="sub-menu" [ngClass]="{'expanded' : hasExpandedHRD}">
        <a mat-list-item *ngIf="showUsers" (click)="isSideNavOpened = false" routerLink="users">
          <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 5px;" width="9" height="9" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          Users
        </a>
        <a mat-list-item (click)="isSideNavOpened = false" routerLink="documents">
          <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 5px;" width="9" height="9" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          Documents
        </a>
      </div>
      
      <mat-list-item *ngIf="showBKD" (click)="hasExpandedBKD = !hasExpandedBKD">
				<span>BKD</span>
			</mat-list-item>
      <div *ngIf="hasAcademicRole && hasExpandedBKD" class="sub-menu" [ngClass]="{'expanded' : hasExpandedBKD}">
        <a mat-list-item (click)="isSideNavOpened = false" routerLink="bkd/report">
          <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 5px;" width="9" height="9" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          Self Report
        </a>
      </div>
      <div *ngIf="canSuperviseBKD && hasExpandedBKD" class="sub-menu" [ngClass]="{'expanded' : hasExpandedBKD}">
        <a mat-list-item (click)="isSideNavOpened = false" routerLink="bkd/summaries">
          <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 5px;" width="9" height="9" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          Summaries
        </a>
      </div>
      <div *ngIf="canEditBKD && hasExpandedBKD" class="sub-menu" [ngClass]="{'expanded' : hasExpandedBKD}">
        <a mat-list-item (click)="isSideNavOpened = false" routerLink="bkd/jabatan-struktural">
          <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 5px;" width="9" height="9" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          Jabatan Struktural
        </a>
        <a mat-list-item (click)="isSideNavOpened = false" routerLink="bkd/pengajaran">
          <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 5px;" width="9" height="9" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          Pengajaran
        </a>
        <a mat-list-item (click)="isSideNavOpened = false" routerLink="bkd/bimbingan-akademik">
          <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 5px;" width="9" height="9" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          Bimbingan Akademik
        </a>
        <a mat-list-item (click)="isSideNavOpened = false" routerLink="bkd/bimbingan-skripsi">
          <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 5px;" width="9" height="9" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          Bimbingan Skripsi
        </a>
        <a mat-list-item (click)="isSideNavOpened = false" routerLink="bkd/bimbingan-kemahasiswaan">
          <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 5px;" width="9" height="9" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          Bimbingan Kemahasiswaan
        </a>
        <a mat-list-item (click)="isSideNavOpened = false" routerLink="bkd/penulisan-bahan-ajar">
          <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 5px;" width="9" height="9" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          Penulisan Bahan Ajar
        </a>
        <a mat-list-item (click)="isSideNavOpened = false" routerLink="bkd/penelitian">
          <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 5px;" width="9" height="9" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          Penelitian
        </a>
        <a mat-list-item (click)="isSideNavOpened = false" routerLink="bkd/pengabdian-masyarakat">
          <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 5px;" width="9" height="9" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          Pengabdian Masyarakat
        </a>
        <a mat-list-item (click)="isSideNavOpened = false" routerLink="bkd/pengembangan-diri">
          <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 5px;" width="9" height="9" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          Pengembangan Diri
        </a>
        <a mat-list-item (click)="isSideNavOpened = false" routerLink="bkd/kepanitiaan-akademik">
          <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 5px;" width="9" height="9" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          Kepanitiaan Akademik
        </a>
        <a mat-list-item (click)="isSideNavOpened = false" routerLink="bkd/kepanitiaan-non-akademik">
          <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 5px;" width="9" height="9" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          Kepanitiaan Non Akademik
        </a>
        <a mat-list-item (click)="isSideNavOpened = false" routerLink="bkd/pekerjaan-lain">
          <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 5px;" width="9" height="9" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          Pekerjaan Lain
        </a>
        <a mat-list-item (click)="isSideNavOpened = false" routerLink="bkd/definisi">
          <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 5px;" width="9" height="9" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          Definisi
        </a>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  
  <mat-sidenav-content>
    <div class="container-fluid">
      <div class="row justify-content-between" *ngIf="showHeader" style="background-color: #800507; color: white; padding-left: 20px; padding-top: 5px; padding-bottom: 10px;">
        <div>
          <div class="float-left" *ngIf="hasLoggedIn" style="margin-top: 20px; cursor:pointer;">
            <svg (click)="isSideNavOpened=!isSideNavOpened" width="30px" height="30px" viewBox="0 0 16 16" class="bi bi-list" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
            </svg>
          </div>
          <div class="float-left" style="margin-left: 10px; margin-top: 5px;">
            <button mat-button style="font-size: 20px;" (click)="isSideNavOpened = false" routerLink="home">
              <img src="assets/images/CIT-Logo-landscape-white.png" style="width: 180px;">
            </button>
          </div>
        </div>
        <label class="switch" style="margin-top: 17px; margin-right: 15px;">
          <input type="checkbox" [checked]="darkMode$ | async" (change)="onToggleDarkModeService()" />
          <span class="slider round"></span>
        </label>
      </div>
    
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
  