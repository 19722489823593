import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { SharedService } from 'src/app/shared.service';
import { UserInterface } from '../../../interfaces/user';
import { ReferenceVariableInterface } from '../../../interfaces/bkd/reference';
import { LecturerTeachingInterface } from '../../../interfaces/bkd/lecturer-teaching';
import { AcademicCourseInterface } from '../../../interfaces/bkd/academic-course';
import { ResponseInterface } from '../../../interfaces/response';

@Component({
  selector: 'app-lecturer-teachings',
  templateUrl: './lecturer-teachings.component.html',
  styleUrls: ['./lecturer-teachings.component.css']
})
export class LecturerTeachingsComponent implements OnInit {

  userName:string;
  isInitializing:boolean = true;

  selectedAcademicYearID:string = '';
  selectedSemesterID:string = '';

  lecturerTeachingList:LecturerTeachingInterface[];
  filteredLecturerTeachingList:LecturerTeachingInterface[];
  sortedLecturerTeachingList:LecturerTeachingInterface[];

  errorMessage:string = '';
  isSubmitting:boolean = false;
  hasSuccessfullySubmitted:boolean = false;

  userList:UserInterface[];
  academicCourseList:AcademicCourseInterface[];
  teachingClassReferenceList:ReferenceVariableInterface[];
  teachingPortionReferenceList:ReferenceVariableInterface[];
  tuitionStaffReferenceList:ReferenceVariableInterface[];
  academicYearReferenceList:ReferenceVariableInterface[];
  semesterReferenceList:ReferenceVariableInterface[];

  lecturer:LecturerTeachingInterface;
  lecturerID:number;
  lecturerUserID:number;
  lecturerAcademicCourseID:number;
  lecturerRefTeachingClassID:number;
  lecturerRefTuitionStaffID:number;
  lecturerRefAcademicYearID:number;
  lecturerRefSemesterID:number;
  lecturerClassStaffCount:number;
  lecturerClassStudentCount:number;
  lecturerClassAttendanceCount:number;
  lecturerClassSessionCount:number;
  lecturerClassModuleCount:number;
  lecturerAttendanceCount:number;
  lecturerSessionCount:number;
  lecturerModuleCount:number;
  lecturerWorkload:number;

  constructor(private service:SharedService, private router:Router) { }

  ngOnInit(): void {
    this.checkCurrentUser();
  }

  checkCurrentUser() {
    this.service.getCurrentUser().subscribe((user:UserInterface)=>{
      this.userName = user.name;
      this.service.checkCurrentUserPermission(user);
      this.isInitializing = false;

      this.getAllLecturerTeachings();
      this.getAllLecturers();
      this.getAllAcademicCourses();
      this.getAllTeachingClassReferences();
      this.getAllTuitionStaffReferences();
      this.getAllAcademicYearReferences();
      this.getAllSemesterReferences();
    }, err => {
      this.isInitializing = false;
      this.router.navigate(['/login']);
    });
  }

  getAllLecturerTeachings() {
    this.service.getAllLecturerTeachings().subscribe((response:LecturerTeachingInterface[])=>{
      this.lecturerTeachingList = response;

      this.filterList();
    }, err => {
      alert(err.error.message);
    });
  }

  filterList() {
    var key1 = this.selectedAcademicYearID;
    var key2 = this.selectedSemesterID;

    if (key1 == '' && key2 == '')
    {
      this.filteredLecturerTeachingList = this.lecturerTeachingList;
    }
    else if ((key1 != '' && key2 == '') || (key1 == '' && key2 != ''))
    {
      this.filteredLecturerTeachingList = this.lecturerTeachingList.filter(function(param)
      {
        return (param.ref_academic_year.id.toString() == key1) || (param.ref_semester.id.toString() == key2)
      });
    }
    else
    {
      this.filteredLecturerTeachingList = this.lecturerTeachingList.filter(function(param)
      {
        return (param.ref_academic_year.id.toString() == key1) && (param.ref_semester.id.toString() == key2)
      });
    }

    this.sortedLecturerTeachingList = this.filteredLecturerTeachingList.slice();
  }

  sortList(sort: Sort) {
    let data = this.lecturerTeachingList;
    
    if (!sort.active || sort.direction === '') {
      this.sortedLecturerTeachingList = data;
      return;
    }

    this.sortedLecturerTeachingList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'user_academic_role': return compare(a.user.academic_role, b.user.academic_role, isAsc);
        case 'user_academic_major': return compare(a.user.academic_major, b.user.academic_major, isAsc);
        case 'user_code': return compare(a.user.code, b.user.code, isAsc);
        case 'user_name': return compare(a.user.name, b.user.name, isAsc);
        case 'value': return compare(a.value, b.value, isAsc);
        default: return 0;
      }
    });
  }

  getAllLecturers() {
    this.service.getAllLecturers().subscribe((response:UserInterface[])=>{
      this.userList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllAcademicCourses() {
    this.service.getAllAcademicCourses().subscribe((response:AcademicCourseInterface[])=>{
      this.academicCourseList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllTeachingClassReferences() {
    this.service.getAllTeachingClassReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.teachingClassReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllTeachingPortionReferences() {
    this.service.getAllTeachingPortionReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.teachingPortionReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllTuitionStaffReferences() {
    this.service.getAllTuitionStaffReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.tuitionStaffReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllAcademicYearReferences() {
    this.service.getAllAcademicYearReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.academicYearReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllSemesterReferences() {
    this.service.getAllSemesterReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.semesterReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  showModal(item?:LecturerTeachingInterface)
  {
    this.hasSuccessfullySubmitted = false;
    this.errorMessage = null;

    if (item != null && item != undefined)
    {
      this.lecturer = item;
      this.lecturerID = this.lecturer.id;
      this.lecturerUserID = this.lecturer.user.id;
      this.lecturerAcademicCourseID = this.lecturer.academic_course.id;
      this.lecturerRefTeachingClassID = this.lecturer.ref_teaching_class.id;
      this.lecturerRefTuitionStaffID = this.lecturer.ref_tuition_staff.id;
      this.lecturerRefAcademicYearID = this.lecturer.ref_academic_year.id;
      this.lecturerRefSemesterID = this.lecturer.ref_semester.id;
      this.lecturerClassStaffCount = this.lecturer.class_lecturer_count;
      this.lecturerClassStudentCount = this.lecturer.class_student_count;
      this.lecturerClassAttendanceCount = this.lecturer.class_attendance_count;
      this.lecturerClassSessionCount = this.lecturer.class_session_count;
      this.lecturerClassModuleCount = this.lecturer.class_module_count;
      this.lecturerAttendanceCount = this.lecturer.attendance_count;
      this.lecturerSessionCount = this.lecturer.session_count;
      this.lecturerModuleCount = this.lecturer.module_count;
      this.lecturerWorkload = this.lecturer.workload;
    }
  }

  closeModal()
  {
    this.lecturer = null;
    this.lecturerID = null;
    this.lecturerUserID = null;
    this.lecturerAcademicCourseID = null;
    this.lecturerRefTeachingClassID = null;
    this.lecturerRefTuitionStaffID = null;
    this.lecturerRefAcademicYearID = null;
    this.lecturerRefSemesterID = null;
    this.lecturerClassStaffCount = null;
    this.lecturerClassStudentCount = null;
    this.lecturerClassAttendanceCount = null;
    this.lecturerClassSessionCount = null;
    this.lecturerClassModuleCount = null;
    this.lecturerAttendanceCount = null;
    this.lecturerSessionCount = null;
    this.lecturerModuleCount = null;
    this.lecturerWorkload = null;
  }

  submit() {
    var param = {
      user_id : this.lecturerUserID,
      academic_course_id : this.lecturerAcademicCourseID,
      ref_teaching_class_id : this.lecturerRefTeachingClassID,
      ref_tuition_staff_id : this.lecturerRefTuitionStaffID,
      ref_academic_year_id : this.lecturerRefAcademicYearID,
      ref_semester_id : this.lecturerRefSemesterID,
      class_lecturer_count : this.lecturerClassStaffCount,
      class_student_count : this.lecturerClassStudentCount,
      class_attendance_count : this.lecturerClassAttendanceCount,
      class_session_count : this.lecturerClassSessionCount,
      class_module_count : this.lecturerClassModuleCount,
      attendance_count : this.lecturerAttendanceCount,
      session_count : this.lecturerSessionCount,
      module_count : this.lecturerModuleCount,
      workload : this.lecturerWorkload
    }

    this.isSubmitting = true;

    var id = null;
    if (this.lecturer != null && this.lecturer != undefined)
    {
      id = this.lecturerID;
    }
    
    this.service.postBKDData('lecturer_teachings', param, id).subscribe((response:ResponseInterface)=>{
      if (response.message == 'ok')
      {
        this.hasSuccessfullySubmitted = true;
        this.getAllLecturerTeachings();
      }
      
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }

  delete() {
    this.isSubmitting = true;

    var id = this.lecturerID;
    
    this.service.deleteBKDData('lecturer_teachings', id).subscribe((response:ResponseInterface)=>{
      if (response.message == 'ok')
      {
        this.hasSuccessfullySubmitted = true;
        this.getAllLecturerTeachings();
      }
      
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
