import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { UserInterface } from '../../interfaces/user';
import { TokenInterface } from '../../interfaces/token';
import { ResponseInterface } from '../../interfaces/response';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  userEmail:string = '';
  userPassword:string = '';

  authenticationErrorMessage:string = '';
  isAuthenticating:boolean = false;
  
  resetPasswordErrorMessage:string = '';
  resetPasswordSuccessMessage:string = '';
  isRequestingResetPassword:boolean = false;
  hasSuccessfullyRequestedResetPassword:boolean = false;

  constructor(private service:SharedService, private router:Router) { }

  ngOnInit(): void {
    this.checkCurrentUser();
  }

  checkCurrentUser() {
    this.service.getCurrentUser().subscribe((user:UserInterface)=>{
      this.router.navigate(['/home']);
    }, err => {
      // Do nothing
    });
  }

  authenticateUserInput() {
    var param = {
      grant_type : 'password',
      scope : '*',
      client_id : '5',
      client_secret : '',
      username : this.userEmail,
      password : this.userPassword
    }

    this.isAuthenticating = true;

    // param.client_secret = 'qzcdF0Ma0YYpXEBjYQMC7Ej1EizvWt90IVoG5Yr9'; // Local
    // param.client_secret = 'B6M0nOOeijEJpCr7vi2qz3n418NeXcHW7uKsrnlv'; // Dev
    param.client_secret = 'I0CsEUEr1UQ3mxdc8KEZ81OK5PvqxscPkSwyJOBf'; // Prod

    this.service.authenticateUser(param).subscribe((token:TokenInterface)=>{
      localStorage.setItem('access_token', token.access_token);

      this.isAuthenticating = false;

      this.router.navigate(['/home']);
    }, err => {
      if (err.error.error == 'invalid_grant')
      {
        this.authenticationErrorMessage = 'Invalid user/password';
      }
      else
      {
        this.authenticationErrorMessage = err.error.error;
      }
      
      this.isAuthenticating = false;
    });
  }

  showResetPasswordModal()
  {
    this.resetPasswordErrorMessage = null;
    this.resetPasswordSuccessMessage = null;
    this.isRequestingResetPassword = null;
    this.hasSuccessfullyRequestedResetPassword = false;
  }

  requestResetPassword() {
    var param = {
      email : this.userEmail
    }

    this.isRequestingResetPassword = true;

    this.service.requestResetPassword(param).subscribe((response:ResponseInterface)=>{
      this.hasSuccessfullyRequestedResetPassword = true;
      this.resetPasswordSuccessMessage = response.message;
      this.isRequestingResetPassword = false;
    }, err => {
      this.resetPasswordErrorMessage = err.error.message;
      this.isRequestingResetPassword = false;
    });
  }
}
