import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { UserInterface } from '../../../interfaces/user';
import { UserAdministrationInterface } from '../../../interfaces/hrd/user-administration-interfaces';

@Component({
  selector: 'app-user-documents-directive',
  templateUrl: './user-documents-directive.component.html',
  styleUrls: ['./user-documents-directive.component.css']
})
export class UserDocumentsDirectiveComponent implements OnInit {

  userName:string;
  isInitializing:boolean = true;

  errorMessage:string = '';
  isSubmitting:boolean = false;
  hasSuccessfullySubmitted:boolean = false;

  selectedUserID:number;
  selectedUserName:string;
  userAdministration:UserAdministrationInterface;

  constructor(
    private service:SharedService,
    private router:Router,
    private route:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.checkCurrentUser();
  }

  checkCurrentUser() {
    this.service.getCurrentUser().subscribe((user:UserInterface)=>{
      this.userName = user.name;
      this.service.checkCurrentUserPermission(user);
      this.isInitializing = false;

      this.selectedUserID = Number(this.route.snapshot.paramMap.get('id')!);

      this.getUserAdministration();
    }, err => {
      this.isInitializing = false;
      this.router.navigate(['/login']);
    });
  }

  getUserAdministration() {
    this.service.getUserAdministration(this.selectedUserID).subscribe((response:UserAdministrationInterface)=>{
      this.userAdministration = response;
      this.selectedUserName = this.userAdministration.name;
    }, err => {
      alert('An error occured');
    });
  }
}
