import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { UserInterface } from '../../../interfaces/user';
import { ReferenceVariableInterface } from '../../../interfaces/bkd/reference';
import { LecturerReportInterface } from '../../../interfaces/bkd/lecturer-report-interfaces';
import { ResponseInterface } from '../../../interfaces/response';

@Component({
  selector: 'app-lecturer-report',
  templateUrl: './lecturer-report.component.html',
  styleUrls: ['./lecturer-report.component.css']
})
export class LecturerReportComponent implements OnInit {

  @ViewChild('myInputFile') myInputFile:ElementRef;
  targetFile:File | null = null;

  userName:string;
  isInitializing:boolean = true;

  selectedAcademicYearID:string = '1';
  selectedSemesterID:string = '1';

  errorMessage:string = '';
  isSubmitting:boolean = false;
  hasSuccessfullySubmitted:boolean = false;

  academicYearReferenceList:ReferenceVariableInterface[];
  semesterReferenceList:ReferenceVariableInterface[];

  selectedLecturerID:number;
  selectedLecturerName:string = '';
  selectedLecturerTotalSKS:number = 0;
  lecturerReport:LecturerReportInterface;
  
  selectedWorkloadID:number;
  selectedDocumentTypeID:number;
  selectedDocumentID:number;
  uploadURL:string;

  constructor(
    private service:SharedService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.checkCurrentUser();
  }

  checkCurrentUser() {
    this.service.getCurrentUser().subscribe((user:UserInterface)=>{
      this.userName = user.name;
      this.service.checkCurrentUserPermission(user);
      this.isInitializing = false;

      this.selectedLecturerID = user.id;

      this.getLecturerReport();
      this.getAllAcademicYearReferences();
      this.getAllSemesterReferences();
    }, err => {
      this.isInitializing = false;
      this.router.navigate(['/login']);
    });
  }

  getLecturerReport() {
    this.service.getLecturerReport(this.selectedLecturerID, this.selectedAcademicYearID, this.selectedSemesterID).subscribe((response:LecturerReportInterface)=>{
      this.lecturerReport = response;
      this.selectedLecturerName = this.lecturerReport.name;
      this.selectedLecturerTotalSKS = this.lecturerReport.total_value;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllAcademicYearReferences() {
    this.service.getAllAcademicYearReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.academicYearReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllSemesterReferences() {
    this.service.getAllSemesterReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.semesterReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  showUploadModal(itemID:number, docTypeID:number)
  {
    this.hasSuccessfullySubmitted = false;
    this.errorMessage = null;

    this.selectedWorkloadID = itemID;
    this.selectedDocumentTypeID = docTypeID;
  }

  showDeleteModal(docID:number)
  {
    this.hasSuccessfullySubmitted = false;
    this.errorMessage = null;

    this.selectedDocumentID = docID;
  }

  closeModal()
  {
    this.selectedWorkloadID = null;
    this.selectedDocumentTypeID = null;
    this.selectedDocumentID = null;
    this.targetFile = null;
    this.uploadURL = null;
  }

  handleFileInput(files: FileList) {
    this.targetFile = files.item(0);
  }

  upload() {
    if (
      (this.uploadURL == null
      || this.uploadURL == '')
      && (this.targetFile == null
      || this.targetFile == undefined)
    ) {
      this.errorMessage = 'Please fill link or select file to be uploaded';
      return;
    }

    this.isSubmitting = true;

    const formData: FormData = new FormData();
    formData.append('url', this.uploadURL);
    formData.append('user_id', this.selectedLecturerID.toString());
    formData.append('reference_id', this.selectedWorkloadID.toString());
    formData.append('bkd_ref_upload_type_id', this.selectedDocumentTypeID.toString());
    
    if (this.targetFile != null && this.targetFile != undefined)
    {
      formData.append('upload', this.targetFile, this.targetFile.name);
    }
    
    this.service.postLecturerUpload(formData).subscribe((response:ResponseInterface)=>{
      this.getLecturerReport();
      this.myInputFile.nativeElement.value = '';
      this.hasSuccessfullySubmitted = true;
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }

  delete() {
    this.isSubmitting = true;
    
    this.service.deleteLecturerUpload(this.selectedDocumentID).subscribe((response:ResponseInterface)=>{
      this.getLecturerReport();
      this.myInputFile.nativeElement.value = '';
      this.hasSuccessfullySubmitted = true;
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }
}
