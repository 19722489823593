import { Component, OnInit } from '@angular/core';
import { DarkModeService } from 'angular-dark-mode';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
  showHeader:boolean = true;
  isSideNavOpened:boolean = false;
  darkMode$: Observable<boolean> = this.darkModeService.darkMode$;

  hasExpandedHRD:boolean = false;
  hasExpandedBKD:boolean = false;

  constructor(
    private service:SharedService,
    private darkModeService:DarkModeService
  ) { }

  ngOnInit(): void {
    
  }

  public onToggleDarkModeService(): void {
    this.darkModeService.toggle();
  }

  public get hasLoggedIn(): boolean {
    return this.service.hasLoggedIn;
  }

  public get showUsers(): boolean {
    return this.service.showUsers;
  }

  public get hasAcademicRole(): boolean {
    return this.service.hasAcademicRole;
  }

  public get showBKD(): boolean {
    return this.service.showBKD;
  }

  public get canEditBKD(): boolean {
    return this.service.canEditBKD;
  }

  public get canSuperviseBKD(): boolean {
    return this.service.canSuperviseBKD;
  }
}
