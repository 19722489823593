import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { UserInterface } from '../../../interfaces/user';
import { ReferenceVariableInterface } from '../../../interfaces/bkd/reference';
import { ResponseInterface } from '../../../interfaces/response';

export interface ReferenceVariableListInterface {
  global_definition:ReferenceVariableInterface[];
  semester:ReferenceVariableInterface[];
  academic_year:ReferenceVariableInterface[];
  structural_position:ReferenceVariableInterface[];
  research:ReferenceVariableInterface[];
  research_staff:ReferenceVariableInterface[];
  tuition_staff:ReferenceVariableInterface[];
  teaching_material:ReferenceVariableInterface[];
  teaching_material_workload:ReferenceVariableInterface[];
  teaching_material_writer:ReferenceVariableInterface[];
  academic_committee:ReferenceVariableInterface[];
  non_academic_committee:ReferenceVariableInterface[];
  teaching_class:ReferenceVariableInterface[];
  teaching_portion:ReferenceVariableInterface[];
  teaching_workload:ReferenceVariableInterface[];
  academic_supervisor:ReferenceVariableInterface[];
  non_academic_supervisor:ReferenceVariableInterface[];
  public_service:ReferenceVariableInterface[];
  self_development:ReferenceVariableInterface[];
  time_category:ReferenceVariableInterface[];
}

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.css']
})
export class ReferencesComponent implements OnInit {

  userName:string;
  isInitializing:boolean = true;

  referenceList:ReferenceVariableListInterface;
  selectedReferenceType:string;
  
  errorMessage:string = '';
  isSubmitting:boolean = false;
  hasSuccessfullySubmitted:boolean = false;
  showCategoryField:boolean = false;

  reference:ReferenceVariableInterface;
  referenceID:number;
  referenceCode:string;
  referenceDescription:string;
  referenceValue:number;
  referenceCategory:string;
  referenceNote:string;

  constructor(private service:SharedService, private router:Router) { }

  ngOnInit(): void {
    this.checkCurrentUser();
  }

  checkCurrentUser() {
    this.service.getCurrentUser().subscribe((user:UserInterface)=>{
      this.userName = user.name;
      this.service.checkCurrentUserPermission(user);
      this.isInitializing = false;

      this.getAllReferences();
    }, err => {
      this.isInitializing = false;
      this.router.navigate(['/login']);
    });
  }

  getAllReferences() {
    this.service.getAllReferences().subscribe((response:ReferenceVariableListInterface)=>{
      this.referenceList = response;
    }, err => {
      alert('An error occured');
    });
  }

  showCreateFormModal(referenceType:string)
  {
    this.hasSuccessfullySubmitted = false;
    this.selectedReferenceType = referenceType;
    this.errorMessage = null;

    this.reference = null;

    if (referenceType == 'BKDRefSelfDevelopmentValue' || referenceType == 'BKDRefPublicServiceValue' || referenceType == 'BKDRefNonAcademicSupervisorValue')
    {
      this.showCategoryField = true;
    }
  }

  showEditFormModal(referenceType:string, item:ReferenceVariableInterface)
  {
    this.hasSuccessfullySubmitted = false;
    this.selectedReferenceType = referenceType;
    this.errorMessage = null;

    this.reference = item;
    this.referenceID = item.id;
    this.referenceCode = item.code;
    this.referenceDescription = item.description;
    this.referenceValue = item.value;
    this.referenceCategory = item.category;
    this.referenceNote = item.note;

    if (referenceType == 'BKDRefSelfDevelopmentValue' || referenceType == 'BKDRefPublicServiceValue' || referenceType == 'BKDRefNonAcademicSupervisorValue')
    {
      this.showCategoryField = true;
    }
  }

  closeFormModal()
  {
    this.selectedReferenceType = null;
    this.errorMessage = null;

    this.reference = null;
    this.referenceID = null;
    this.referenceCode = null;
    this.referenceDescription = null;
    this.referenceValue = null;
    this.referenceCategory = null;
    this.referenceNote = null;

    this.showCategoryField = false;
  }

  submit() {
    var param = {
      type : this.selectedReferenceType,
      code : this.referenceCode,
      description : this.referenceDescription,
      value : this.referenceValue,
      category : this.referenceCategory,
      note : this.referenceNote
    }

    this.isSubmitting = true;

    var id = null;
    if (this.reference != null && this.reference != undefined)
    {
      id = this.referenceID;
    }
    
    this.service.postBKDData('refs', param, id).subscribe((response:ResponseInterface)=>{
      if (response.message == 'ok')
      {
        this.hasSuccessfullySubmitted = true;
        this.getAllReferences();
      }
      
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }
}
