<div class="row align-items-center" style="padding: 5px;">
    <div class="col-md-12 text-right">
        <div class="float-right" style="margin-right: 40px;">
            <h3 *ngIf="userName">Welcome, {{ userName }}</h3>
        </div>
    </div>
</div>

<div class="container py-3" *ngIf="isInitializing">
    <div class="sk-folding-cube">
        <div class="sk-cube1 sk-cube"></div>
        <div class="sk-cube2 sk-cube"></div>
        <div class="sk-cube4 sk-cube"></div>
        <div class="sk-cube3 sk-cube"></div>
    </div>
</div>

<div class="container py-3" *ngIf="userName">
	<div class="row">
		<div class="col-sm-12">
			<h1>Bimbingan Skripsi</h1>
		</div>
    </div>
    <div class="row">
        <div class="col">
            <select [(ngModel)]="selectedAcademicYearID" (ngModelChange)="filterList()" class="form-control">
                <option value="" selected>Filter by academic Year</option>
                <option *ngFor="let academicYearReference of academicYearReferenceList" 
                    [value]="academicYearReference.id">
                    {{ academicYearReference.code }}
                </option>
            </select>
        </div>
        <div class="col">
            <select [(ngModel)]="selectedSemesterID" (ngModelChange)="filterList()" class="form-control">
                <option value="" selected>Filter by semester</option>
                <option *ngFor="let semesterReference of semesterReferenceList" 
                    [value]="semesterReference.id">
                    {{ semesterReference.description }}
                </option>
            </select>
        </div>
        <div class="col" style="text-align: right;">
            <button (click)="showModal()" type="button" class="btn btn-success" data-toggle="modal" data-target="#calculate-modal" data-keyboard="false">
                Add new
            </button>
        </div>
    </div>
    <br>
    <div class="row">
		<table class="table table-bordered" matSort (matSortChange)="sortList($event)">
			<thead>
				<tr>
					<th mat-sort-header="user_academic_role">Status</th>
					<th mat-sort-header="user_academic_major">Prodi</th>
					<th mat-sort-header="user_code">NIP</th>
					<th mat-sort-header="user_name">Nama</th>
					<th mat-sort-header="value">SKS</th>
                    <th style="text-align: center;">Action</th>
					<th>Jumlah Murid</th>
                </tr>
			</thead>
			<tbody>
                <tr *ngFor="let item of sortedLecturerThesisSupervisorList">
                    <td>{{ item.user.academic_role }}</td>
                    <td>{{ item.user.academic_major }}</td>
                    <td>{{ item.user.code }}</td>
                    <td>{{ item.user.name }}</td>
                    <td style="text-align: center;">{{ item.value }}</td>
                    <td style="width: 100px;">
                        <div class="row text-center" style="padding-left: 5px; padding-right: 5px;">
                            <div class="col" style="padding: 0px;">
                                <button (click)="showModal(item)" type="button" class="btn btn-light" data-toggle="modal" data-target="#calculate-modal" data-keyboard="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="col" style="padding: 0px;">
                                <button (click)="showModal(item)" type="button" class="btn btn-light" data-toggle="modal" data-target="#delete-modal" data-keyboard="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </td>
					<td style="text-align: center;">{{ item.student_count }}</td>
                </tr>
			</tbody>
		</table>
    </div>
</div>

<!-- Calculate Modal -->
<div class="modal fade" id="calculate-modal" data-backdrop="false" tabindex="-1" aria-labelledby="calculate-modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="calculate-modal-label">Calculate SKS</h2>
                <button (click)="closeModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="hasSuccessfullySubmitted">
                    <div class="d-flex justify-content-center">
                        <svg id="successAnimation" class="animated" xmlns="http://www.w3.org/2000/svg" width="300" height="300" viewBox="0 0 70 70">
                            <path id="successAnimationResult" fill="#D8D8D8" d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"/>
                            <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" stroke-width="2" stroke-linecap="round" fill="green"/>
                            <polyline id="successAnimationCheck" stroke="#979797" stroke-width="2" points="23 34 34 43 47 27" fill="transparent"/>
                        </svg>
                    </div>
                    <br>
                    <div class="form-group row">
                      <div class="col-sm-12 text-center">
                        Successfully updated
                      </div>
                    </div>
                </div>
                <div *ngIf="!hasSuccessfullySubmitted">
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">User</label>
                        <div class="col-sm-10">
                            <select [(ngModel)]="lecturerUserID" class="form-control">
                                <option *ngFor="let user of userList" 
                                    [value]="user.id" 
                                    [attr.selected]="lecturer && lecturer.user.id==user.id ? true : null">
                                    {{ user.code }}-{{ user.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Jumlah Murid</label>
                        <div class="col-sm-10">
                            <input type="number" class="form-control" [(ngModel)]="lecturerStudentCount">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Tahun Akademik</label>
                        <div class="col-sm-10">
                            <select [(ngModel)]="lecturerRefAcademicYearID" class="form-control">
                                <option *ngFor="let academicYearReference of academicYearReferenceList" 
                                    [value]="academicYearReference.id" 
                                    [attr.selected]="lecturer && lecturer.ref_academic_year.id==academicYearReference.id ? true : null">
                                    {{ academicYearReference.code }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Semester</label>
                        <div class="col-sm-10">
                            <select [(ngModel)]="lecturerRefSemesterID" class="form-control">
                                <option *ngFor="let semesterReference of semesterReferenceList" 
                                    [value]="semesterReference.id" 
                                    [attr.selected]="lecturer && lecturer.ref_semester.id==semesterReference.id ? true : null">
                                    {{ semesterReference.description }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label style="color: red;">{{ errorMessage }}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <button (click)="submit()" [disabled]="isSubmitting" class="btn btn-success" style="width: 100%;">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Delete Modal -->
<div class="modal fade" id="delete-modal" data-backdrop="false" tabindex="-1" aria-labelledby="delete-modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="form-modal-label">Delete</h2>
                <button (click)="closeModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="hasSuccessfullySubmitted">
                    <div class="d-flex justify-content-center">
                        <svg id="successAnimation" class="animated" xmlns="http://www.w3.org/2000/svg" width="300" height="300" viewBox="0 0 70 70">
                            <path id="successAnimationResult" fill="#D8D8D8" d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"/>
                            <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" stroke-width="2" stroke-linecap="round" fill="green"/>
                            <polyline id="successAnimationCheck" stroke="#979797" stroke-width="2" points="23 34 34 43 47 27" fill="transparent"/>
                        </svg>
                    </div>
                    <br>
                    <div class="form-group row">
                      <div class="col-sm-12 text-center">
                        Successfully deleted
                      </div>
                    </div>
                </div>
                <div *ngIf="!hasSuccessfullySubmitted">
                    <div class="form-group row">
                        <div class="col-sm-12">
                            Are you sure to delete this item?
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label style="color: red;">{{ errorMessage }}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <button (click)="delete()" [disabled]="isSubmitting" class="btn btn-danger" style="width: 100%;">
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
