import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { SharedService } from 'src/app/shared.service';
import { UserInterface } from '../../../interfaces/user';
import { ReferenceVariableInterface } from '../../../interfaces/bkd/reference';
import { LecturerResearchInterface } from '../../../interfaces/bkd/lecturer-research';
import { ResponseInterface } from '../../../interfaces/response';

@Component({
  selector: 'app-lecturer-researches',
  templateUrl: './lecturer-researches.component.html',
  styleUrls: ['./lecturer-researches.component.css']
})
export class LecturerResearchesComponent implements OnInit {

  userName:string;
  isInitializing:boolean = true;

  selectedAcademicYearID:string = '';
  selectedSemesterID:string = '';

  lecturerResearchList:LecturerResearchInterface[];
  filteredLecturerResearchList:LecturerResearchInterface[];
  sortedLecturerResearchList:LecturerResearchInterface[];

  errorMessage:string = '';
  isSubmitting:boolean = false;
  hasSuccessfullySubmitted:boolean = false;

  userList:UserInterface[];
  researchReferenceList:ReferenceVariableInterface[];
  researchStaffReferenceList:ReferenceVariableInterface[];
  academicYearReferenceList:ReferenceVariableInterface[];
  semesterReferenceList:ReferenceVariableInterface[];

  lecturer:LecturerResearchInterface;
  lecturerID:number;
  lecturerUserID:number;
  lecturerRefResearchID:number;
  lecturerRefResearchStaffID:number;
  lecturerRefAcademicYearID:number;
  lecturerRefSemesterID:number;
  lecturerResearchCode:string;
  lecturerResearchTitle:string;
  lecturerWorkload:number;

  constructor(private service:SharedService, private router:Router) { }

  ngOnInit(): void {
    this.checkCurrentUser();
  }

  checkCurrentUser() {
    this.service.getCurrentUser().subscribe((user:UserInterface)=>{
      this.userName = user.name;
      this.service.checkCurrentUserPermission(user);
      this.isInitializing = false;

      this.getAllLecturerResearches();
      this.getAllLecturers();
      this.getAllResearchStaffReferences();
      this.getAllResearchReferences();
      this.getAllAcademicYearReferences();
      this.getAllSemesterReferences();
    }, err => {
      this.isInitializing = false;
      this.router.navigate(['/login']);
    });
  }

  getAllLecturerResearches() {
    this.service.getAllLecturerResearches().subscribe((response:LecturerResearchInterface[])=>{
      this.lecturerResearchList = response;

      this.filterList();
    }, err => {
      alert(err.error.message);
    });
  }

  filterList() {
    var key1 = this.selectedAcademicYearID;
    var key2 = this.selectedSemesterID;

    if (key1 == '' && key2 == '')
    {
      this.filteredLecturerResearchList = this.lecturerResearchList;
    }
    else if ((key1 != '' && key2 == '') || (key1 == '' && key2 != ''))
    {
      this.filteredLecturerResearchList = this.lecturerResearchList.filter(function(param)
      {
        return (param.ref_academic_year.id.toString() == key1) || (param.ref_semester.id.toString() == key2)
      });
    }
    else
    {
      this.filteredLecturerResearchList = this.lecturerResearchList.filter(function(param)
      {
        return (param.ref_academic_year.id.toString() == key1) && (param.ref_semester.id.toString() == key2)
      });
    }

    this.sortedLecturerResearchList = this.filteredLecturerResearchList.slice();
  }

  sortList(sort: Sort) {
    let data = this.lecturerResearchList;
    
    if (!sort.active || sort.direction === '') {
      this.sortedLecturerResearchList = data;
      return;
    }

    this.sortedLecturerResearchList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'user_academic_role': return compare(a.user.academic_role, b.user.academic_role, isAsc);
        case 'user_academic_major': return compare(a.user.academic_major, b.user.academic_major, isAsc);
        case 'user_code': return compare(a.user.code, b.user.code, isAsc);
        case 'user_name': return compare(a.user.name, b.user.name, isAsc);
        case 'value': return compare(a.value, b.value, isAsc);
        case 'research_code': return compare(a.research_code, b.research_code, isAsc);
        default: return 0;
      }
    });
  }

  getAllLecturers() {
    this.service.getAllLecturers().subscribe((response:UserInterface[])=>{
      this.userList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllResearchStaffReferences() {
    this.service.getAllResearchStaffReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.researchStaffReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllResearchReferences() {
    this.service.getAllResearchReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.researchReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllAcademicYearReferences() {
    this.service.getAllAcademicYearReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.academicYearReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllSemesterReferences() {
    this.service.getAllSemesterReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.semesterReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  showModal(item?:LecturerResearchInterface)
  {
    this.hasSuccessfullySubmitted = false;
    this.errorMessage = null;

    if (item != null && item != undefined)
    {
      this.lecturer = item;
      this.lecturerID = this.lecturer.id;
      this.lecturerUserID = this.lecturer.user.id;
      this.lecturerRefResearchID = this.lecturer.ref_research.id;
      this.lecturerRefResearchStaffID = this.lecturer.ref_research_staff.id;
      this.lecturerRefAcademicYearID = this.lecturer.ref_academic_year.id;
      this.lecturerRefSemesterID = this.lecturer.ref_semester.id;
      this.lecturerResearchCode = this.lecturer.research_code;
      this.lecturerResearchTitle = this.lecturer.research_title;
      this.lecturerWorkload = this.lecturer.workload;
    }
  }

  closeModal()
  {
    this.lecturer = null;
    this.lecturerID = null;
    this.lecturerUserID = null;
    this.lecturerRefResearchID = null;
    this.lecturerRefResearchStaffID = null;
    this.lecturerRefAcademicYearID = null;
    this.lecturerRefSemesterID = null;
    this.lecturerResearchCode = null;
    this.lecturerResearchTitle = null;
    this.lecturerWorkload = null;
  }

  submit() {
    var param = {
      user_id : this.lecturerUserID,
      ref_research_id : this.lecturerRefResearchID,
      ref_research_staff_id : this.lecturerRefResearchStaffID,
      ref_academic_year_id : this.lecturerRefAcademicYearID,
      ref_semester_id : this.lecturerRefSemesterID,
      research_code : this.lecturerResearchCode,
      research_title : this.lecturerResearchTitle,
      workload : this.lecturerWorkload
    }

    this.isSubmitting = true;

    var id = null;
    if (this.lecturer != null && this.lecturer != undefined)
    {
      id = this.lecturerID;
    }
    
    this.service.postBKDData('lecturer_researches', param, id).subscribe((response:ResponseInterface)=>{
      if (response.message == 'ok')
      {
        this.hasSuccessfullySubmitted = true;
        this.getAllLecturerResearches();
      }
      
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }

  delete() {
    this.isSubmitting = true;

    var id = this.lecturerID;
    
    this.service.deleteBKDData('lecturer_researches', id).subscribe((response:ResponseInterface)=>{
      if (response.message == 'ok')
      {
        this.hasSuccessfullySubmitted = true;
        this.getAllLecturerResearches();
      }
      
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
