<div class="row align-items-center" style="padding: 5px;">
    <div class="col-md-12 text-right">
        <div class="float-right" style="margin-right: 40px;">
            <h3 *ngIf="userName">Welcome, {{ userName }}</h3>
        </div>
    </div>
</div>

<div class="container py-3" *ngIf="isInitializing">
    <div class="sk-folding-cube">
        <div class="sk-cube1 sk-cube"></div>
        <div class="sk-cube2 sk-cube"></div>
        <div class="sk-cube4 sk-cube"></div>
        <div class="sk-cube3 sk-cube"></div>
    </div>
</div>

<div class="container py-3" *ngIf="userName">
	<div class="row">
		<div class="col">
            <h1>Global Definition</h1>
		</div>
		<div class="col" style="text-align: right;">
            <!-- <button (click)="showCreateFormModal('BKDRefGlobalDefinitionValue')" type="button" class="btn btn-success" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Add new
            </button> -->
		</div>
    </div>
    <div class="row">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>ID</th>
					<th>Kode</th>
					<th>Penjabaran</th>
					<th>Nilai</th>
					<th>Catatan</th>
                    <th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
				<tr *ngFor="let reference of referenceList.global_definition" style="height: 30px;">
                    <td style="text-align: center;">{{ reference.id }}</td>
                    <td style="text-align: center;">{{ reference.code }}</td>
                    <td>{{ reference.description }}</td>
                    <td style="text-align: right;">{{ reference.value }}</td>
                    <td>{{ reference.note }}</td>
                    <td style="text-align: center;">
                        <button (click)="showEditFormModal('BKDRefGlobalDefinitionValue', reference)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
    <br><br><br>
	<div class="row">
		<div class="col">
            <h1>Semester</h1>
		</div>
		<div class="col" style="text-align: right;">
            <!-- <button (click)="showCreateFormModal('BKDRefSemesterValue')" type="button" class="btn btn-success" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Add new
            </button> -->
		</div>
    </div>
    <div class="row">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>ID</th>
					<th>Kode</th>
					<th>Penjabaran</th>
					<th>Nilai</th>
					<th>Catatan</th>
                    <th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
				<tr *ngFor="let reference of referenceList.semester">
                    <td style="text-align: center;">{{ reference.id }}</td>
                    <td style="text-align: center;">{{ reference.code }}</td>
                    <td>{{ reference.description }}</td>
                    <td style="text-align: right;">{{ reference.value }}</td>
                    <td>{{ reference.note }}</td>
                    <td style="text-align: center;">
                        <button (click)="showEditFormModal('BKDRefSemesterValue', reference)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
    <br><br><br>
	<div class="row">
		<div class="col">
            <h1>Tahun Akademik</h1>
		</div>
		<div class="col" style="text-align: right;">
            <button (click)="showCreateFormModal('BKDRefAcademicYearValue')" type="button" class="btn btn-success" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Add new
            </button>
		</div>
    </div>
    <div class="row">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>ID</th>
					<th>Kode</th>
					<th>Penjabaran</th>
					<th>Nilai</th>
					<th>Catatan</th>
                    <th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
				<tr *ngFor="let reference of referenceList.academic_year">
                    <td style="text-align: center;">{{ reference.id }}</td>
                    <td style="text-align: center;">{{ reference.code }}</td>
                    <td>{{ reference.description }}</td>
                    <td style="text-align: right;">{{ reference.value }}</td>
                    <td>{{ reference.note }}</td>
                    <td style="text-align: center;">
                        <button (click)="showEditFormModal('BKDRefAcademicYearValue', reference)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
    <br><br><br>
	<div class="row">
		<div class="col">
            <h1>Jabatan Struktural</h1>
		</div>
		<div class="col" style="text-align: right;">
            <button (click)="showCreateFormModal('BKDRefStructuralPositionValue')" type="button" class="btn btn-success" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Add new
            </button>
		</div>
    </div>
    <div class="row">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>ID</th>
					<th>Kode</th>
					<th>Penjabaran</th>
					<th>Nilai</th>
					<th>Catatan</th>
                    <th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
				<tr *ngFor="let reference of referenceList.structural_position">
                    <td style="text-align: center;">{{ reference.id }}</td>
                    <td style="text-align: center;">{{ reference.code }}</td>
                    <td>{{ reference.description }}</td>
                    <td style="text-align: right;">{{ reference.value }}</td>
                    <td>{{ reference.note }}</td>
                    <td style="text-align: center;">
                        <button (click)="showEditFormModal('BKDRefStructuralPositionValue', reference)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
    <br><br><br>
	<div class="row">
		<div class="col">
            <h1>Penelitian</h1>
		</div>
		<div class="col" style="text-align: right;">
            <button (click)="showCreateFormModal('BKDRefResearchValue')" type="button" class="btn btn-success" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Add new
            </button>
		</div>
    </div>
    <div class="row">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>ID</th>
					<th>Kode</th>
					<th>Penjabaran</th>
					<th>Nilai</th>
					<th>Catatan</th>
                    <th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
				<tr *ngFor="let reference of referenceList.research">
                    <td style="text-align: center;">{{ reference.id }}</td>
                    <td style="text-align: center;">{{ reference.code }}</td>
                    <td>{{ reference.description }}</td>
                    <td style="text-align: right;">{{ reference.value }}</td>
                    <td>{{ reference.note }}</td>
                    <td style="text-align: center;">
                        <button (click)="showEditFormModal('BKDRefResearchValue', reference)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
    <br><br><br>
	<div class="row">
		<div class="col">
            <h1>Tenaga Peneliti</h1>
		</div>
		<div class="col" style="text-align: right;">
            <button (click)="showCreateFormModal('BKDRefResearchStaffValue')" type="button" class="btn btn-success" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Add new
            </button>
		</div>
    </div>
    <div class="row">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>ID</th>
					<th>Kode</th>
					<th>Penjabaran</th>
					<th>Nilai</th>
					<th>Catatan</th>
                    <th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
				<tr *ngFor="let reference of referenceList.research_staff">
                    <td style="text-align: center;">{{ reference.id }}</td>
                    <td style="text-align: center;">{{ reference.code }}</td>
                    <td>{{ reference.description }}</td>
                    <td style="text-align: right;">{{ reference.value }}</td>
                    <td>{{ reference.note }}</td>
                    <td style="text-align: center;">
                        <button (click)="showEditFormModal('BKDRefResearchStaffValue', reference)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
    <br><br><br>
	<div class="row">
		<div class="col">
            <h1>Tenaga Pendidik</h1>
		</div>
		<div class="col" style="text-align: right;">
            <button (click)="showCreateFormModal('BKDRefTuitionStaffValue')" type="button" class="btn btn-success" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Add new
            </button>
		</div>
    </div>
    <div class="row">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>ID</th>
					<th>Kode</th>
					<th>Penjabaran</th>
					<th>Nilai</th>
					<th>Catatan</th>
                    <th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
				<tr *ngFor="let reference of referenceList.tuition_staff">
                    <td style="text-align: center;">{{ reference.id }}</td>
                    <td style="text-align: center;">{{ reference.code }}</td>
                    <td>{{ reference.description }}</td>
                    <td style="text-align: right;">{{ reference.value }}</td>
                    <td>{{ reference.note }}</td>
                    <td style="text-align: center;">
                        <button (click)="showEditFormModal('BKDRefTuitionStaffValue', reference)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
    <br><br><br>
	<div class="row">
		<div class="col">
            <h1>Penulisan Bahan Ajar</h1>
		</div>
		<div class="col" style="text-align: right;">
            <button (click)="showCreateFormModal('BKDRefTeachingMaterialValue')" type="button" class="btn btn-success" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Add new
            </button>
		</div>
    </div>
    <div class="row">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>ID</th>
					<th>Kode</th>
					<th>Penjabaran</th>
					<th>Nilai</th>
					<th>Catatan</th>
                    <th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
				<tr *ngFor="let reference of referenceList.teaching_material">
                    <td style="text-align: center;">{{ reference.id }}</td>
                    <td style="text-align: center;">{{ reference.code }}</td>
                    <td>{{ reference.description }}</td>
                    <td style="text-align: right;">{{ reference.value }}</td>
                    <td>{{ reference.note }}</td>
                    <td style="text-align: center;">
                        <button (click)="showEditFormModal('BKDRefTeachingMaterialValue', reference)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
    <br>
	<div class="row" style="height: 45px;">
		<div class="col-sm-12" style="text-align: right;">
            <button (click)="showCreateFormModal('BKDRefTeachingMaterialWriterValue')" type="button" class="btn btn-success" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Add new
            </button>
		</div>
    </div>
    <div class="row">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>ID</th>
					<th>Kode</th>
					<th>Penjabaran</th>
					<th>Nilai</th>
					<th>Catatan</th>
                    <th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
				<tr *ngFor="let reference of referenceList.teaching_material_writer">
                    <td style="text-align: center;">{{ reference.id }}</td>
                    <td style="text-align: center;">{{ reference.code }}</td>
                    <td>{{ reference.description }}</td>
                    <td style="text-align: right;">{{ reference.value }}</td>
                    <td>{{ reference.note }}</td>
                    <td style="text-align: center;">
                        <button (click)="showEditFormModal('BKDRefTeachingMaterialWriterValue', reference)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
    <br>
	<div class="row" style="height: 45px;">
		<div class="col-sm-12" style="text-align: right;">
            <button (click)="showCreateFormModal('BKDRefTeachingMaterialWorkloadValue')" type="button" class="btn btn-success" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Add new
            </button>
		</div>
    </div>
    <div class="row">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>ID</th>
					<th>Kode</th>
					<th>Penjabaran</th>
					<th>Nilai</th>
					<th>Catatan</th>
                    <th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
				<tr *ngFor="let reference of referenceList.teaching_material_workload">
                    <td style="text-align: center;">{{ reference.id }}</td>
                    <td style="text-align: center;">{{ reference.code }}</td>
                    <td>{{ reference.description }}</td>
                    <td style="text-align: right;">{{ reference.value }}</td>
                    <td>{{ reference.note }}</td>
                    <td style="text-align: center;">
                        <button (click)="showEditFormModal('BKDRefTeachingMaterialWorkloadValue', reference)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
    <br><br><br>
	<div class="row">
		<div class="col">
            <h1>Kepanitiaan Akademik</h1>
		</div>
		<div class="col" style="text-align: right;">
            <button (click)="showCreateFormModal('BKDRefAcademicCommitteeValue')" type="button" class="btn btn-success" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Add new
            </button>
		</div>
    </div>
    <div class="row">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>ID</th>
					<th>Kode</th>
					<th>Penjabaran</th>
					<th>Nilai</th>
					<th>Catatan</th>
                    <th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
				<tr *ngFor="let reference of referenceList.academic_committee">
                    <td style="text-align: center;">{{ reference.id }}</td>
                    <td style="text-align: center;">{{ reference.code }}</td>
                    <td>{{ reference.description }}</td>
                    <td style="text-align: right;">{{ reference.value }}</td>
                    <td>{{ reference.note }}</td>
                    <td style="text-align: center;">
                        <button (click)="showEditFormModal('BKDRefAcademicCommitteeValue', reference)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
    <br><br><br>
	<div class="row">
		<div class="col">
            <h1>Kepanitiaan Non Akademik</h1>
		</div>
		<div class="col" style="text-align: right;">
            <button (click)="showCreateFormModal('BKDRefNonAcademicCommitteeValue')" type="button" class="btn btn-success" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Add new
            </button>
		</div>
    </div>
    <div class="row">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>ID</th>
					<th>Kode</th>
					<th>Penjabaran</th>
					<th>Nilai</th>
					<th>Catatan</th>
                    <th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
				<tr *ngFor="let reference of referenceList.non_academic_committee">
                    <td style="text-align: center;">{{ reference.id }}</td>
                    <td style="text-align: center;">{{ reference.code }}</td>
                    <td>{{ reference.description }}</td>
                    <td style="text-align: right;">{{ reference.value }}</td>
                    <td>{{ reference.note }}</td>
                    <td style="text-align: center;">
                        <button (click)="showEditFormModal('BKDRefNonAcademicCommitteeValue', reference)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
    <br><br><br>
	<div class="row">
		<div class="col">
            <h1>Pengajaran Mata Kuliah</h1>
		</div>
		<div class="col" style="text-align: right;">
            <button (click)="showCreateFormModal('BKDRefTeachingClassValue')" type="button" class="btn btn-success" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Add new
            </button>
		</div>
    </div>
    <div class="row">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>ID</th>
					<th>Kode</th>
					<th>Penjabaran</th>
					<th>Nilai</th>
					<th>Catatan</th>
                    <th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
				<tr *ngFor="let reference of referenceList.teaching_class">
                    <td style="text-align: center;">{{ reference.id }}</td>
                    <td style="text-align: center;">{{ reference.code }}</td>
                    <td>{{ reference.description }}</td>
                    <td style="text-align: right;">{{ reference.value }}</td>
                    <td>{{ reference.note }}</td>
                    <td style="text-align: center;">
                        <button (click)="showEditFormModal('BKDRefTeachingClassValue', reference)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
    <br>
	<div class="row" style="height: 45px;">
		<div class="col-sm-12" style="text-align: right;">
            <button (click)="showCreateFormModal('BKDRefTeachingPortionValue')" type="button" class="btn btn-success" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Add new
            </button>
		</div>
    </div>
    <div class="row">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>ID</th>
					<th>Kode</th>
					<th>Penjabaran</th>
					<th>Nilai</th>
					<th>Catatan</th>
                    <th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
				<tr *ngFor="let reference of referenceList.teaching_portion">
                    <td style="text-align: center;">{{ reference.id }}</td>
                    <td style="text-align: center;">{{ reference.code }}</td>
                    <td>{{ reference.description }}</td>
                    <td style="text-align: right;">{{ reference.value }}</td>
                    <td>{{ reference.note }}</td>
                    <td style="text-align: center;">
                        <button (click)="showEditFormModal('BKDRefTeachingPortionValue', reference)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
    <br>
	<div class="row" style="height: 45px;">
		<div class="col-sm-12" style="text-align: right;">
            <button (click)="showCreateFormModal('BKDRefTeachingWorkloadValue')" type="button" class="btn btn-success" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Add new
            </button>
		</div>
    </div>
    <div class="row">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>ID</th>
					<th>Kode</th>
					<th>Penjabaran</th>
					<th>Nilai</th>
					<th>Catatan</th>
                    <th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
				<tr *ngFor="let reference of referenceList.teaching_workload">
                    <td style="text-align: center;">{{ reference.id }}</td>
                    <td style="text-align: center;">{{ reference.code }}</td>
                    <td>{{ reference.description }}</td>
                    <td style="text-align: right;">{{ reference.value }}</td>
                    <td>{{ reference.note }}</td>
                    <td style="text-align: center;">
                        <button (click)="showEditFormModal('BKDRefTeachingWorkloadValue', reference)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
    <br><br><br>
	<div class="row">
		<div class="col">
            <h1>Dosen Pembimbing Akademik</h1>
		</div>
		<div class="col" style="text-align: right;">
            <!-- <button (click)="showCreateFormModal('BKDRefAcademicSupervisorValue')" type="button" class="btn btn-success" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Add new
            </button> -->
		</div>
    </div>
    <div class="row">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>ID</th>
					<th>Kode</th>
					<th>Penjabaran</th>
					<th>Nilai</th>
					<th>Kategori</th>
					<th>Catatan</th>
                    <th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
				<tr *ngFor="let reference of referenceList.academic_supervisor">
                    <td style="text-align: center;">{{ reference.id }}</td>
                    <td style="text-align: center;">{{ reference.code }}</td>
                    <td>{{ reference.description }}</td>
                    <td style="text-align: right;">{{ reference.value }}</td>
                    <td style="text-align: center;">{{ reference.category }}</td>
                    <td>{{ reference.note }}</td>
                    <td style="text-align: center;">
                        <button (click)="showEditFormModal('BKDRefAcademicSupervisorValue', reference)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
    <br><br><br>
	<div class="row">
		<div class="col">
            <h1>Dosen Pembimbing Non Akademik</h1>
		</div>
		<div class="col" style="text-align: right;">
            <button (click)="showCreateFormModal('BKDRefNonAcademicSupervisorValue')" type="button" class="btn btn-success" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Add new
            </button>
		</div>
    </div>
    <div class="row">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>ID</th>
					<th>Kode</th>
					<th>Penjabaran</th>
					<th>Nilai</th>
					<th>Kategori</th>
					<th>Catatan</th>
                    <th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
				<tr *ngFor="let reference of referenceList.non_academic_supervisor">
                    <td style="text-align: center;">{{ reference.id }}</td>
                    <td style="text-align: center;">{{ reference.code }}</td>
                    <td>{{ reference.description }}</td>
                    <td style="text-align: right;">{{ reference.value }}</td>
                    <td style="text-align: center;">{{ reference.category }}</td>
                    <td>{{ reference.note }}</td>
                    <td style="text-align: center;">
                        <button (click)="showEditFormModal('BKDRefNonAcademicSupervisorValue', reference)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
    <br><br><br>
	<div class="row">
		<div class="col">
            <h1>Pengabdian Masyarakat</h1>
		</div>
		<div class="col" style="text-align: right;">
            <button (click)="showCreateFormModal('BKDRefPublicServiceValue')" type="button" class="btn btn-success" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Add new
            </button>
		</div>
    </div>
    <div class="row">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>ID</th>
					<th>Kode</th>
					<th>Penjabaran</th>
					<th>Nilai</th>
					<th>Kategori</th>
					<th>Catatan</th>
                    <th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
				<tr *ngFor="let reference of referenceList.public_service">
                    <td style="text-align: center;">{{ reference.id }}</td>
                    <td style="text-align: center;">{{ reference.code }}</td>
                    <td>{{ reference.description }}</td>
                    <td style="text-align: right;">{{ reference.value }}</td>
                    <td style="text-align: center;">{{ reference.category }}</td>
                    <td>{{ reference.note }}</td>
                    <td style="text-align: center;">
                        <button (click)="showEditFormModal('BKDRefPublicServiceValue', reference)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
    <br><br><br>
	<div class="row">
		<div class="col">
            <h1>Pengembangan Diri</h1>
		</div>
		<div class="col" style="text-align: right;">
            <button (click)="showCreateFormModal('BKDRefSelfDevelopmentValue')" type="button" class="btn btn-success" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Add new
            </button>
		</div>
    </div>
    <div class="row">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>ID</th>
					<th>Kode</th>
					<th>Penjabaran</th>
					<th>Nilai</th>
					<th>Kategori</th>
					<th>Catatan</th>
                    <th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
				<tr *ngFor="let reference of referenceList.self_development">
                    <td style="text-align: center;">{{ reference.id }}</td>
                    <td style="text-align: center;">{{ reference.code }}</td>
                    <td>{{ reference.description }}</td>
                    <td style="text-align: right;">{{ reference.value }}</td>
                    <td style="text-align: center;">{{ reference.category }}</td>
                    <td>{{ reference.note }}</td>
                    <td style="text-align: center;">
                        <button (click)="showEditFormModal('BKDRefSelfDevelopmentValue', reference)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
    <br><br><br>
	<div class="row">
		<div class="col">
            <h1>Kategori Waktu</h1>
		</div>
		<div class="col" style="text-align: right;">
            <button (click)="showCreateFormModal('BKDRefTimeCategoryValue')" type="button" class="btn btn-success" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Add new
            </button>
		</div>
    </div>
    <div class="row">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th>ID</th>
					<th>Kode</th>
					<th>Penjabaran</th>
					<th>Nilai</th>
					<th>Catatan</th>
                    <th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
				<tr *ngFor="let reference of referenceList.time_category">
                    <td style="text-align: center;">{{ reference.id }}</td>
                    <td style="text-align: center;">{{ reference.code }}</td>
                    <td>{{ reference.description }}</td>
                    <td style="text-align: right;">{{ reference.value }}</td>
                    <td>{{ reference.note }}</td>
                    <td style="text-align: center;">
                        <button (click)="showEditFormModal('BKDRefTimeCategoryValue', reference)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
</div>

<!-- Form Modal -->
<div class="modal fade" id="form-modal" data-backdrop="false" tabindex="-1" aria-labelledby="form-modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="form-modal-label">Form</h2>
                <button (click)="closeFormModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="hasSuccessfullySubmitted">
                    <div class="d-flex justify-content-center">
                        <svg id="successAnimation" class="animated" xmlns="http://www.w3.org/2000/svg" width="300" height="300" viewBox="0 0 70 70">
                            <path id="successAnimationResult" fill="#D8D8D8" d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"/>
                            <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" stroke-width="2" stroke-linecap="round" fill="green"/>
                            <polyline id="successAnimationCheck" stroke="#979797" stroke-width="2" points="23 34 34 43 47 27" fill="transparent"/>
                        </svg>
                    </div>
                    <br>
                    <div class="form-group row">
                      <div class="col-sm-12 text-center">
                        Successfully updated
                      </div>
                    </div>
                </div>
                <div *ngIf="!hasSuccessfullySubmitted">
                    <div class="form-group row" *ngIf="referenceID">
                        <label class="col-sm-2 col-form-label">ID</label>
                        <div class="col-sm-10">
                            <input type="number" class="form-control" value="{{ referenceID }}" disabled>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Kode</label>
                        <div class="col-sm-10">
                            <input type="text" [(ngModel)]="referenceCode" class="form-control">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Penjabaran</label>
                        <div class="col-sm-10">
                            <input type="text" [(ngModel)]="referenceDescription" class="form-control">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Nilai</label>
                        <div class="col-sm-10">
                            <input type="number" [(ngModel)]="referenceValue" class="form-control">
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="showCategoryField">
                        <label class="col-sm-2 col-form-label">Kategori</label>
                        <div class="col-sm-10">
                            <input type="text" [(ngModel)]="referenceCategory" class="form-control">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Catatan</label>
                        <div class="col-sm-10">
                            <input type="text" [(ngModel)]="referenceNote" class="form-control">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label style="color: red;">{{ errorMessage }}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <button (click)="submit()" [disabled]="isSubmitting" class="btn btn-success" style="width: 100%;">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
