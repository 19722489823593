import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { SharedService } from 'src/app/shared.service';
import { UserInterface } from '../../../interfaces/user';
import { ReferenceVariableInterface } from '../../../interfaces/bkd/reference';
import { LecturerAcademicSupervisorInterface } from '../../../interfaces/bkd/lecturer-academic-supervisor';
import { ResponseInterface } from '../../../interfaces/response';

@Component({
  selector: 'app-lecturer-academic-supervisors',
  templateUrl: './lecturer-academic-supervisors.component.html',
  styleUrls: ['./lecturer-academic-supervisors.component.css']
})
export class LecturerAcademicSupervisorsComponent implements OnInit {

  userName:string;
  isInitializing:boolean = true;

  selectedAcademicYearID:string = '';
  selectedSemesterID:string = '';

  lecturerAcademicSupervisorList:LecturerAcademicSupervisorInterface[];
  filteredLecturerAcademicSupervisorList:LecturerAcademicSupervisorInterface[];
  sortedLecturerAcademicSupervisorList:LecturerAcademicSupervisorInterface[];

  errorMessage:string = '';
  isSubmitting:boolean = false;
  hasSuccessfullySubmitted:boolean = false;

  userList:UserInterface[];
  academicYearReferenceList:ReferenceVariableInterface[];
  semesterReferenceList:ReferenceVariableInterface[];

  lecturer:LecturerAcademicSupervisorInterface;
  lecturerID:number;
  lecturerUserID:number;
  lecturerRefAcademicYearID:number;
  lecturerRefSemesterID:number;
  lecturerStudentCount:number;

  constructor(private service:SharedService, private router:Router) { }

  ngOnInit(): void {
    this.checkCurrentUser();
  }

  checkCurrentUser() {
    this.service.getCurrentUser().subscribe((user:UserInterface)=>{
      this.userName = user.name;
      this.service.checkCurrentUserPermission(user);
      this.isInitializing = false;

      this.getAllLecturerAcademicSupervisors();
      this.getAllLecturers();
      this.getAllAcademicYearReferences();
      this.getAllSemesterReferences();
    }, err => {
      this.isInitializing = false;
      this.router.navigate(['/login']);
    });
  }

  getAllLecturerAcademicSupervisors() {
    this.service.getAllLecturerAcademicSupervisors().subscribe((response:LecturerAcademicSupervisorInterface[])=>{
      this.lecturerAcademicSupervisorList = response;

      this.filterList();
    }, err => {
      alert(err.error.message);
    });
  }

  filterList() {
    var key1 = this.selectedAcademicYearID;
    var key2 = this.selectedSemesterID;

    if (key1 == '' && key2 == '')
    {
      this.filteredLecturerAcademicSupervisorList = this.lecturerAcademicSupervisorList;
    }
    else if ((key1 != '' && key2 == '') || (key1 == '' && key2 != ''))
    {
      this.filteredLecturerAcademicSupervisorList = this.lecturerAcademicSupervisorList.filter(function(param)
      {
        return (param.ref_academic_year.id.toString() == key1) || (param.ref_semester.id.toString() == key2)
      });
    }
    else
    {
      this.filteredLecturerAcademicSupervisorList = this.lecturerAcademicSupervisorList.filter(function(param)
      {
        return (param.ref_academic_year.id.toString() == key1) && (param.ref_semester.id.toString() == key2)
      });
    }

    this.sortedLecturerAcademicSupervisorList = this.filteredLecturerAcademicSupervisorList.slice();
  }

  sortList(sort: Sort) {
    let data = this.lecturerAcademicSupervisorList;
    
    if (!sort.active || sort.direction === '') {
      this.sortedLecturerAcademicSupervisorList = data;
      return;
    }

    this.sortedLecturerAcademicSupervisorList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'user_academic_role': return compare(a.user.academic_role, b.user.academic_role, isAsc);
        case 'user_academic_major': return compare(a.user.academic_major, b.user.academic_major, isAsc);
        case 'user_code': return compare(a.user.code, b.user.code, isAsc);
        case 'user_name': return compare(a.user.name, b.user.name, isAsc);
        case 'value': return compare(a.value, b.value, isAsc);
        default: return 0;
      }
    });
  }

  getAllLecturers() {
    this.service.getAllLecturers().subscribe((response:UserInterface[])=>{
      this.userList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllAcademicYearReferences() {
    this.service.getAllAcademicYearReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.academicYearReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllSemesterReferences() {
    this.service.getAllSemesterReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.semesterReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  showModal(item?:LecturerAcademicSupervisorInterface)
  {
    this.hasSuccessfullySubmitted = false;
    this.errorMessage = null;

    if (item != null && item != undefined)
    {
      this.lecturer = item;
      this.lecturerID = this.lecturer.id;
      this.lecturerUserID = this.lecturer.user.id;
      this.lecturerRefAcademicYearID = this.lecturer.ref_academic_year.id;
      this.lecturerRefSemesterID = this.lecturer.ref_semester.id;
      this.lecturerStudentCount = this.lecturer.student_count;
    }
  }

  closeModal()
  {
    this.lecturer = null;
    this.lecturerID = null;
    this.lecturerUserID = null;
    this.lecturerRefAcademicYearID = null;
    this.lecturerRefSemesterID = null;
    this.lecturerStudentCount = null;
  }

  submit() {
    var param = {
      user_id : this.lecturerUserID,
      ref_academic_year_id : this.lecturerRefAcademicYearID,
      ref_semester_id : this.lecturerRefSemesterID,
      student_count : this.lecturerStudentCount
    }

    this.isSubmitting = true;

    var id = null;
    if (this.lecturer != null && this.lecturer != undefined)
    {
      id = this.lecturerID;
    }
    
    this.service.postBKDData('lecturer_academic_supervisors', param, id).subscribe((response:ResponseInterface)=>{
      if (response.message == 'ok')
      {
        this.hasSuccessfullySubmitted = true;
        this.getAllLecturerAcademicSupervisors();
      }
      
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }

  delete() {
    this.isSubmitting = true;

    var id = this.lecturerID;
    
    this.service.deleteBKDData('lecturer_academic_supervisors', id).subscribe((response:ResponseInterface)=>{
      if (response.message == 'ok')
      {
        this.hasSuccessfullySubmitted = true;
        this.getAllLecturerAcademicSupervisors();
      }
      
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
