import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { UserInterface } from '../../interfaces/user';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  userName:string;
  isInitializing:boolean = true;

  constructor(private service:SharedService, private router:Router) { }

  ngOnInit(): void {
    this.checkCurrentUser();
  }

  checkCurrentUser() {
    this.service.getCurrentUser().subscribe((user:UserInterface)=>{
      this.userName = user.name;
      this.service.checkCurrentUserPermission(user);
      this.isInitializing = false;
    }, err => {
      this.isInitializing = false;
      this.router.navigate(['/login']);
    });
  }
}
