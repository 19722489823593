<div class="row align-items-center" style="padding: 5px;">
    <div class="col-md-12 text-right">
        <div class="float-right" style="margin-right: 40px;">
            <h3 *ngIf="userName">Welcome, {{ userName }}</h3>
        </div>
    </div>
</div>

<div class="container py-3" *ngIf="isInitializing">
    <div class="sk-folding-cube">
        <div class="sk-cube1 sk-cube"></div>
        <div class="sk-cube2 sk-cube"></div>
        <div class="sk-cube4 sk-cube"></div>
        <div class="sk-cube3 sk-cube"></div>
    </div>
</div>

<div class="container py-3" *ngIf="userName">
    <div class="row">
        <div class="col" style="padding: 10px;">
            <div class="layout-box" style="width: 100%;">
                <div class="form-group row">
                    <div class="col-sm-12">
                        <h2>Profile</h2>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">NIP</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control" [(ngModel)]="userCode" readonly>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Name</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control" [(ngModel)]="userName" readonly>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Email</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control" [(ngModel)]="userEmail" readonly>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">NIK</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control" [(ngModel)]="userNIK" readonly>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">NPWP</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control" [(ngModel)]="userNPWP" readonly>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">No. HP</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control" [(ngModel)]="userPhone" readonly>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Gender</label>
                    <div class="col-sm-9">
                        <input *ngIf="userGender===1" type="text" class="form-control" value="Laki-laki" readonly>
                        <input *ngIf="userGender===0" type="text" class="form-control" value="Perempuan" readonly>
                        <input *ngIf="userGender!==1 && userGender!==0" type="text" class="form-control" value="" readonly>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Tempat Lahir</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control" [(ngModel)]="userBirthPlace" readonly>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Tanggal Lahir</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control" [(ngModel)]="userBirthPlace" readonly>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Alamat</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control" [(ngModel)]="userAddressStreet" readonly>
                    </div>
                </div>
                <div class="form-group row" *ngIf="userAccessRole">
                    <label class="col-sm-3 col-form-label">Access Role</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control" [(ngModel)]="userAccessRole" readonly>
                    </div>
                </div>
                <div class="form-group row" *ngIf="userAcademicRole">
                    <label class="col-sm-3 col-form-label">Academic Role</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control" [(ngModel)]="userAcademicRole" readonly>
                    </div>
                </div>
                <div class="form-group row" *ngIf="userAcademicPortion">
                    <label class="col-sm-3 col-form-label">Availability</label>
                    <div class="col-sm-9">
                        <div class="input-group">
                            <input type="number" class="form-control" [(ngModel)]="userAcademicPortion" readonly>
                            <div class="input-group-append">
                                <span class="input-group-text">%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row" *ngIf="userAcademicMajor">
                    <label class="col-sm-3 col-form-label">Academic Major</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control" [(ngModel)]="userAcademicMajor" readonly>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <button data-toggle="modal" data-target="#result-modal" data-keyboard="false" (click)="logout()" data-toggle="modal" data-target="#result-modal" data-keyboard="false" class="btn btn-secondary" style="width: 100%;">
                            Logout
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col" style="padding: 10px;">
            <div class="layout-box" style="width: 100%;">
                <div class="form-group row">
                    <div class="col-sm-12">
                        <h2>Update Password</h2>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Old Password</label>
                    <div class="col-sm-9">
                        <input type="password" class="form-control" [(ngModel)]="userPassword">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">New Password</label>
                    <div class="col-sm-9">
                        <input type="password" class="form-control" [(ngModel)]="userNewPassword">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <button data-toggle="modal" data-target="#result-modal" data-keyboard="false" (click)="updatePassword()" class="btn btn-success" style="width: 100%;">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Result Modal -->
<div class="modal fade" id="result-modal" data-backdrop="false" tabindex="-1" aria-labelledby="result-modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="result-modal-label">Process</h2>
                <button *ngIf="!hasSuccessfullySubmitted && !isSubmitting" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="isSubmitting">
                    <div class="sk-folding-cube">
                        <div class="sk-cube1 sk-cube"></div>
                        <div class="sk-cube2 sk-cube"></div>
                        <div class="sk-cube4 sk-cube"></div>
                        <div class="sk-cube3 sk-cube"></div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        
                    </div>
                </div>
                <div *ngIf="hasSuccessfullySubmitted && !isSubmitting">
                    <div class="d-flex justify-content-center">
                        <svg id="successAnimation" class="animated" xmlns="http://www.w3.org/2000/svg" width="300" height="300" viewBox="0 0 70 70">
                            <path id="successAnimationResult" fill="#D8D8D8" d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"/>
                            <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" stroke-width="2" stroke-linecap="round" fill="green"/>
                            <polyline id="successAnimationCheck" stroke="#979797" stroke-width="2" points="23 34 34 43 47 27" fill="transparent"/>
                        </svg>
                    </div>
                    <br>
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            Successfully updated. You will be logged out.
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <button (click)="logout()" class="btn btn-secondary" style="width: 100%;">
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-group row" *ngIf="errorMessage">
                    <div class="col-sm-12 text-center">
                        <label style="color: red;">{{ errorMessage }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
