import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedService } from 'src/app/shared.service';
import { UserInterface } from '../../../interfaces/user';
import { ReferenceVariableInterface } from '../../../interfaces/bkd/reference';
import { LecturerReportInterface } from '../../../interfaces/bkd/lecturer-report-interfaces';
import { LecturerWorkloadItemInterface } from '../../../interfaces/bkd/lecturer-report-interfaces';
import { ResponseInterface } from '../../../interfaces/response';

@Component({
  selector: 'app-lecturer-report-directive',
  templateUrl: './lecturer-report-directive.component.html',
  styleUrls: ['./lecturer-report-directive.component.css']
})
export class LecturerReportDirectiveComponent implements OnInit {

  userName:string;
  isInitializing:boolean = true;

  selectedAcademicYearID:string = '1';
  selectedSemesterID:string = '1';

  errorMessage:string = '';
  isSubmitting:boolean = false;
  hasSuccessfullySubmitted:boolean = false;

  academicYearReferenceList:ReferenceVariableInterface[];
  semesterReferenceList:ReferenceVariableInterface[];

  columnName:string = null;
  selectedLecturerID:number;
  selectedLecturerName:string = '';
  selectedLecturerTotalSKS:number = 0;
  lecturerReport:LecturerReportInterface;

  encryptedAgreementLetter:any;
  encryptedAddendumLetter:any;
  letterLink:any;

  constructor(
    private service:SharedService,
    private router:Router,
    private route:ActivatedRoute,
    private sanitizer:DomSanitizer
  ) { }

  ngOnInit(): void {
    this.checkCurrentUser();
  }

  checkCurrentUser() {
    this.service.getCurrentUser().subscribe((user:UserInterface)=>{
      this.userName = user.name;
      this.service.checkCurrentUserPermission(user);
      this.isInitializing = false;

      this.selectedLecturerID = Number(this.route.snapshot.paramMap.get('id')!);

      this.getLecturerReport();
      this.getAllAcademicYearReferences();
      this.getAllSemesterReferences();

      if (this.service.userRole == 'ADMINISTRATOR' || this.service.userRole == 'REKTORAT')
      {
        this.columnName = 'approved';
      }
      else if (this.service.userRole == 'KAPRODI' || this.service.userRole == 'LPPM')
      {
        this.columnName = 'verified';
      }
      else if (this.service.userRole == 'STAFF_IT' || this.service.userRole == 'STAFF_HR')
      {
        this.columnName = null;
      }
      else
      {
        this.router.navigate(['/home']);
      }
    }, err => {
      this.isInitializing = false;
      this.router.navigate(['/login']);
    });
  }

  getLecturerReport() {
    this.service.getLecturerReport(this.selectedLecturerID, this.selectedAcademicYearID, this.selectedSemesterID).subscribe((response:LecturerReportInterface)=>{
      this.lecturerReport = response;
      this.selectedLecturerName = this.lecturerReport.name;
      this.selectedLecturerTotalSKS = this.lecturerReport.total_value;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllAcademicYearReferences() {
    this.service.getAllAcademicYearReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.academicYearReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllSemesterReferences() {
    this.service.getAllSemesterReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.semesterReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getActionButtonAvailability(item:LecturerWorkloadItemInterface) {
    if (this.service.userRole == 'ADMINISTRATOR' || this.service.userRole == 'REKTORAT')
    {
      return item.is_approved;
    }
    else if (this.service.userRole == 'KAPRODI' || this.service.userRole == 'LPPM')
    {
      return item.is_verified;
    }
    else if (this.service.userRole == 'STAFF_IT' || this.service.userRole == 'STAFF_HR')
    {
      return -1;
    }

    return -1;
  }

  submit(workloadKey:string, workloadID:number) {
    if (this.columnName == null)
    {
      this.errorMessage = 'Permission denied.';
      return;
    }

    var param = {
      table_postfix : workloadKey,
      column_name : this.columnName,
      row_id : workloadID
    }

    this.hasSuccessfullySubmitted = false;
    this.errorMessage = null;
    this.isSubmitting = true;
    this.letterLink = null;
    this.service.updateFlagStatus(param).subscribe((response:ResponseInterface)=>{
      if (response.message == 'ok')
      {
        this.hasSuccessfullySubmitted = true;
        this.getLecturerReport();
      }
      
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }

  submitAll() {
    var param = {
      user_id : this.selectedLecturerID,
      academic_year_id : this.selectedAcademicYearID,
      semester_id : this.selectedSemesterID,
      column_name : this.columnName
    }

    this.hasSuccessfullySubmitted = false;
    this.errorMessage = null;
    this.isSubmitting = true;
    this.letterLink = null;
    this.service.updateAllFlagStatus(param).subscribe((response:ResponseInterface)=>{
      if (response.message == 'ok')
      {
        this.hasSuccessfullySubmitted = true;
        this.getLecturerReport();
      }
      
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }

  cancel(workloadKey:string, workloadID:number) {
    if (this.columnName == null)
    {
      this.errorMessage = 'Permission denied.';
      return;
    }

    var param = {
      table_postfix : workloadKey,
      column_name : this.columnName,
      row_id : workloadID
    }

    this.hasSuccessfullySubmitted = false;
    this.errorMessage = null;
    this.isSubmitting = true;
    this.letterLink = null;
    this.service.removeFlagStatus(param).subscribe((response:ResponseInterface)=>{
      if (response.message == 'ok')
      {
        this.hasSuccessfullySubmitted = true;
        this.getLecturerReport();
      }
      
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }

  cancelAll() {
    var param = {
      user_id : this.selectedLecturerID,
      academic_year_id : this.selectedAcademicYearID,
      semester_id : this.selectedSemesterID,
      column_name : this.columnName
    }

    this.hasSuccessfullySubmitted = false;
    this.errorMessage = null;
    this.isSubmitting = true;
    this.letterLink = null;
    this.service.removeAllFlagStatus(param).subscribe((response:ResponseInterface)=>{
      if (response.message == 'ok')
      {
        this.hasSuccessfullySubmitted = true;
        this.getLecturerReport();
      }
      
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }

  getLecturerAgreement() {
    this.hasSuccessfullySubmitted = false;
    this.errorMessage = null;
    this.isSubmitting = true;

    this.service.getLecturerAgreement(this.selectedLecturerID, this.selectedAcademicYearID, this.selectedSemesterID).subscribe((response:any)=>{
      this.encryptedAgreementLetter = response.encrypted;
      this.downloadLetter(this.encryptedAgreementLetter);

      this.hasSuccessfullySubmitted = true;
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }

  getLecturerAddendum() {
    this.hasSuccessfullySubmitted = false;
    this.errorMessage = null;
    this.isSubmitting = true;

    var selectedWorkloadList:string[] = [];
    var workloadCheckBoxList = document.getElementsByName("selected_workload");
    for (var i = 0; i < workloadCheckBoxList.length; i++)
    {
      var workloadCheckBox = (workloadCheckBoxList[i] as HTMLInputElement);

      if (workloadCheckBox.checked == true)
      {
        selectedWorkloadList.push(workloadCheckBox.value);
      }
    }

    if (selectedWorkloadList.length == 0)
    {
      this.errorMessage = 'No item selected.';
      this.isSubmitting = false;
      return;
    }

    var param = {
      workloads : selectedWorkloadList
    }

    this.service.getLecturerAddendum(this.selectedLecturerID, param).subscribe((response:any)=>{
      this.encryptedAddendumLetter = response.encrypted;
      this.downloadLetter(this.encryptedAddendumLetter);

      this.hasSuccessfullySubmitted = true;
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }

  downloadLetter(encryptedData:any) {
    const byteArray = new Uint8Array(atob(encryptedData).split('').map(char => char.charCodeAt(0)));
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    this.letterLink = url;
    window.open(url);
  }

  sanitize(url:string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
