import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { UserInterface } from './interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  // readonly api_url = 'http://localhost:8000';
  // readonly api_url = 'https://dev.api.calvin.ac.id';
  readonly api_url = 'https://api.calvin.ac.id';

  public hasLoggedIn:boolean = false;
  public showUsers:boolean = false;
  public userRole:string = '';
  public hasAcademicRole:boolean = false;

  public showBKD:boolean = false;
  public canEditBKD:boolean = false;
  public canSuperviseBKD:boolean = false;
  public showBKDAcademicMajorID:string = '';

  constructor(private http:HttpClient) { }

  authenticateUser(param:any) {
  	return this.http.post(this.api_url + '/oauth/token', param);
  }

  requestResetPassword(param:any) {
  	return this.http.post(this.api_url + '/hr/user/password/request', param);
  }

  updatePassword(param:any, id:number) {
    return this.http.post(this.api_url + '/hr/users/' + id + '/password', param);
  }

  receiveGetResponse(targetURL:string) {
    let headers = new HttpHeaders();
    var accessToken = localStorage.getItem('access_token');
    headers = headers.set('Authorization', 'Bearer ' + accessToken);
    return this.http.get(targetURL, {headers: headers});
  }

  receivePostResponse(targetURL:string, param:any) {
    let headers = new HttpHeaders();
    var accessToken = localStorage.getItem('access_token');
    headers = headers.set('Authorization', 'Bearer ' + accessToken);
    return this.http.post(targetURL, param, {headers: headers});
  }

  receiveDeleteResponse(targetURL:string) {
    let headers = new HttpHeaders();
    var accessToken = localStorage.getItem('access_token');
    headers = headers.set('Authorization', 'Bearer ' + accessToken);
    return this.http.delete(targetURL, {headers: headers});
  }

  getCurrentUser() {
    this.hasLoggedIn = false;
    this.showUsers = false;
    this.userRole = '';
    this.hasAcademicRole = false;

    this.showBKD = false;
    this.canEditBKD = false;
    this.canSuperviseBKD = false;
    this.showBKDAcademicMajorID = '';

  	return this.receiveGetResponse(this.api_url + '/hr/user');
  }

  checkCurrentUserPermission(user:UserInterface)
  {
    this.hasLoggedIn = true;
    var role = user.access_role;

    if (role == 'ADMINISTRATOR' || role == 'REKTORAT' || role == 'STAFF_HR' || role == 'STAFF_IT')
    {
      this.showUsers = true;
      this.showBKD = true;
      this.canEditBKD = true;
      this.canSuperviseBKD = true;
      this.showBKDAcademicMajorID = '';
    }
    else if (role == 'KAPRODI')
    {
      this.showUsers = false;
      this.showBKD = true;
      this.canEditBKD = true;
      this.canSuperviseBKD = true;
      this.showBKDAcademicMajorID = user.ref_academic_major_id.toString();
    }
    else if (role == 'LPPM')
    {
      this.showUsers = false;
      this.showBKD = true;
      this.canEditBKD = false;
      this.canSuperviseBKD = true;
      this.showBKDAcademicMajorID = '';
    }
    else if (role == 'DOSEN' || role == 'LABORAN')
    {
      this.showUsers = false;
      this.showBKD = true;
      this.canEditBKD = false;
      this.canSuperviseBKD = false;
      this.showBKDAcademicMajorID = user.ref_academic_major_id.toString();
    }
    else
    {
      this.showUsers = false;
      this.showBKD = false;
      this.canEditBKD = false;
      this.canSuperviseBKD = false;
    }

    // DOSEN TETAP or DOSEN TAMU
    if (user.ref_academic_role_id == 1 || user.ref_academic_role_id == 2)
    {
      this.hasAcademicRole = true;
    }

    this.userRole = role;
  }

  logout() {
  	return this.receivePostResponse(this.api_url + '/hr/user/logout', null);
  }

  getAllUsers() {
    return this.receiveGetResponse(this.api_url + '/hr/users');
  }

  getAllUserAcademicRoles() {
    return this.receiveGetResponse(this.api_url + '/hr/user_academic_roles');
  }

  getAllUserAccessRoles() {
    return this.receiveGetResponse(this.api_url + '/hr/user_access_roles');
  }

  postUser(param:any, id?:number) {
    if (id == null || id == undefined)
    {
      return this.receivePostResponse(this.api_url + '/hr/users', param);
    }
    else
    {
      return this.receivePostResponse(this.api_url + '/hr/users/' + id, param);
    }
  }

  deleteUser(id:number) {
    return this.receiveDeleteResponse(this.api_url + '/hr/users/' + id);
  }

  getUserAdministration(userID) {
    return this.receiveGetResponse(this.api_url + '/hr/users/' + userID + '/uploads');
  }

  postUserUpload(param:any) {
    return this.receivePostResponse(this.api_url + '/hr/users/uploads', param);
  }

  deleteUserUpload(id:number) {
    return this.receiveDeleteResponse(this.api_url + '/hr/users/uploads/' + id);
  }

  getAllLecturers() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/lecturers');
  }

  getAllLecturerWorkloadSummaries(refAcademicYearID, refSemesterID) {
    var queryString1 = refAcademicYearID == '' ? '' : '&ref_academic_year_id=' + refAcademicYearID;
    var queryString2 = refSemesterID == '' ? '' : '&ref_semester_id=' + refSemesterID;
    return this.receiveGetResponse(this.api_url + '/hr/bkd/lecturer_summaries?academic_major_id=' + this.showBKDAcademicMajorID + queryString1 + queryString2);
  }

  getLecturerReport(userID, refAcademicYearID, refSemesterID) {
    var queryString1 = refAcademicYearID == '' ? '?ref_academic_year_id=1' : '?ref_academic_year_id=' + refAcademicYearID;
    var queryString2 = refSemesterID == '' ? '&ref_semester_id=1' : '&ref_semester_id=' + refSemesterID;
    return this.receiveGetResponse(this.api_url + '/hr/bkd/lecturers/' + userID + '/report' + queryString1 + queryString2);
  }

  getLecturerAgreement(userID, refAcademicYearID, refSemesterID) {
    var queryString1 = refAcademicYearID == '' ? '?ref_academic_year_id=1' : '?ref_academic_year_id=' + refAcademicYearID;
    var queryString2 = refSemesterID == '' ? '&ref_semester_id=1' : '&ref_semester_id=' + refSemesterID;
    return this.receiveGetResponse(this.api_url + '/hr/bkd/lecturers/' + userID + '/agreement' + queryString1 + queryString2);
  }

  getLecturerAddendum(userID, param:any) {
    return this.receivePostResponse(this.api_url + '/hr/bkd/lecturers/' + userID + '/addendum', param);
  }

  updateFlagStatus(param:any)
  {
    return this.receivePostResponse(this.api_url + '/hr/bkd/update_status', param);
  }

  updateAllFlagStatus(param:any)
  {
    return this.receivePostResponse(this.api_url + '/hr/bkd/update_all_status', param);
  }

  removeFlagStatus(param:any)
  {
    return this.receivePostResponse(this.api_url + '/hr/bkd/remove_status', param);
  }

  removeAllFlagStatus(param:any)
  {
    return this.receivePostResponse(this.api_url + '/hr/bkd/remove_all_status', param);
  }

  postLecturerUpload(param:any) {
    return this.receivePostResponse(this.api_url + '/hr/bkd/lecturers/uploads', param);
  }

  deleteLecturerUpload(id:number) {
    return this.receiveDeleteResponse(this.api_url + '/hr/bkd/lecturers/uploads/' + id);
  }

  getAllLecturerStructuralPositions() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/lecturer_structural_positions?academic_major_id=' + this.showBKDAcademicMajorID);
  }

  getAllLecturerTeachings() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/lecturer_teachings?academic_major_id=' + this.showBKDAcademicMajorID);
  }

  getAllLecturerAcademicSupervisors() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/lecturer_academic_supervisors?academic_major_id=' + this.showBKDAcademicMajorID);
  }

  getAllLecturerThesisSupervisors() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/lecturer_thesis_supervisors?academic_major_id=' + this.showBKDAcademicMajorID);
  }

  getAllLecturerStudentAffairSupervisors() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/lecturer_student_affair_supervisors?academic_major_id=' + this.showBKDAcademicMajorID);
  }

  getAllLecturerTeachingMaterialWritings() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/lecturer_teaching_material_writings?academic_major_id=' + this.showBKDAcademicMajorID);
  }

  getAllLecturerResearches() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/lecturer_researches?academic_major_id=' + this.showBKDAcademicMajorID);
  }

  getAllLecturerPublicServices() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/lecturer_public_services?academic_major_id=' + this.showBKDAcademicMajorID);
  }

  getAllLecturerSelfDevelopments() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/lecturer_self_developments?academic_major_id=' + this.showBKDAcademicMajorID);
  }

  getAllLecturerAcademicCommittees() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/lecturer_academic_committees?academic_major_id=' + this.showBKDAcademicMajorID);
  }

  getAllLecturerNonAcademicCommittees() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/lecturer_non_academic_committees?academic_major_id=' + this.showBKDAcademicMajorID);
  }

  getAllLecturerOtherWorks() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/lecturer_other_works?academic_major_id=' + this.showBKDAcademicMajorID);
  }

  getAllReferences() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/refs');
  }

  getAllAcademicCommitteeReferences() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/academic_committee_refs');
  }

  getAllAcademicSupervisorReferences() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/academic_supervisor_refs');
  }

  getAllAcademicYearReferences() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/academic_year_refs');
  }

  getAllGlobalDefinitionReferences() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/global_definition_refs');
  }

  getAllNonAcademicCommitteeReferences() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/non_academic_committee_refs');
  }

  getAllNonAcademicSupervisorReferences() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/non_academic_supervisor_refs');
  }

  getAllPublicServiceReferenceListReferences() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/public_service_refs');
  }

  getAllResearchStaffReferences() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/research_staff_refs');
  }

  getAllResearchReferences() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/research_refs');
  }

  getAllSelfDevelopmentReferences() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/self_development_refs');
  }

  getAllSemesterReferences() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/semester_refs');
  }

  getAllStructuralPositionReferences() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/structural_position_refs');
  }

  getAllTeachingClassReferences() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/teaching_class_refs');
  }

  getAllTeachingMaterialReferences() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/teaching_material_refs');
  }

  getAllTeachingMaterialWorkloadReferences() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/teaching_material_workload_refs');
  }

  getAllTeachingMaterialWriterReferences() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/teaching_material_writer_refs');
  }

  getAllTeachingPortionReferences() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/teaching_portion_refs');
  }

  getAllTeachingWorkloadReferences() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/teaching_workload_refs');
  }

  getAllTimeCategoryReferences() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/time_category_refs');
  }

  getAllTuitionStaffReferences() {
    return this.receiveGetResponse(this.api_url + '/hr/bkd/tuition_staff_refs');
  }

  getAllAcademicCourses() {
    return this.receiveGetResponse(this.api_url + '/portal/academic_courses');
  }

  postBKDData(endpoint:string, param:any, id?:number) {
    if (id == null || id == undefined)
    {
      return this.receivePostResponse(this.api_url + '/hr/bkd/' + endpoint, param);
    }
    else
    {
      return this.receivePostResponse(this.api_url + '/hr/bkd/' + endpoint + '/' + id, param);
    }
  }

  deleteBKDData(endpoint:string, id:number) {
    return this.receiveDeleteResponse(this.api_url + '/hr/bkd/' + endpoint + '/' + id);
  }
}
