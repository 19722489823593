import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { SharedService } from 'src/app/shared.service';
import { UserInterface } from '../../../interfaces/user';
import { ReferenceVariableInterface } from '../../../interfaces/bkd/reference';
import { LecturerSelfDevelopmentInterface } from '../../../interfaces/bkd/lecturer-self-development';
import { ResponseInterface } from '../../../interfaces/response';

@Component({
  selector: 'app-lecturer-self-developments',
  templateUrl: './lecturer-self-developments.component.html',
  styleUrls: ['./lecturer-self-developments.component.css']
})
export class LecturerSelfDevelopmentsComponent implements OnInit {

  userName:string;
  isInitializing:boolean = true;

  selectedAcademicYearID:string = '';
  selectedSemesterID:string = '';

  lecturerSelfDevelopmentList:LecturerSelfDevelopmentInterface[];
  filteredLecturerSelfDevelopmentList:LecturerSelfDevelopmentInterface[];
  sortedLecturerSelfDevelopmentList:LecturerSelfDevelopmentInterface[];

  errorMessage:string = '';
  isSubmitting:boolean = false;
  hasSuccessfullySubmitted:boolean = false;

  userList:UserInterface[];
  selfDevelopmentReferenceList:ReferenceVariableInterface[];
  timeCategoryReferenceList:ReferenceVariableInterface[];
  academicYearReferenceList:ReferenceVariableInterface[];
  semesterReferenceList:ReferenceVariableInterface[];

  lecturer:LecturerSelfDevelopmentInterface;
  lecturerID:number;
  lecturerUserID:number;
  lecturerRefSelfDevelopmentID:number;
  lecturerRefTimeCategoryID:number;
  lecturerRefAcademicYearID:number;
  lecturerRefSemesterID:number;
  lecturerActivityName:string;

  constructor(private service:SharedService, private router:Router) { }

  ngOnInit(): void {
    this.checkCurrentUser();
  }

  checkCurrentUser() {
    this.service.getCurrentUser().subscribe((user:UserInterface)=>{
      this.userName = user.name;
      this.service.checkCurrentUserPermission(user);
      this.isInitializing = false;
      
      this.getAllLecturerSelfDevelopments();
      this.getAllLecturers();
      this.getAllSelfDevelopmentReferences();
      this.getAllTimeCategoryReferences();
      this.getAllAcademicYearReferences();
      this.getAllSemesterReferences();
    }, err => {
      this.isInitializing = false;
      this.router.navigate(['/login']);
    });
  }

  getAllLecturerSelfDevelopments() {
    this.service.getAllLecturerSelfDevelopments().subscribe((response:LecturerSelfDevelopmentInterface[])=>{
      this.lecturerSelfDevelopmentList = response;

      this.filterList();
    }, err => {
      alert(err.error.message);
    });
  }

  filterList() {
    var key1 = this.selectedAcademicYearID;
    var key2 = this.selectedSemesterID;

    if (key1 == '' && key2 == '')
    {
      this.filteredLecturerSelfDevelopmentList = this.lecturerSelfDevelopmentList;
    }
    else if ((key1 != '' && key2 == '') || (key1 == '' && key2 != ''))
    {
      this.filteredLecturerSelfDevelopmentList = this.lecturerSelfDevelopmentList.filter(function(param)
      {
        return (param.ref_academic_year.id.toString() == key1) || (param.ref_semester.id.toString() == key2)
      });
    }
    else
    {
      this.filteredLecturerSelfDevelopmentList = this.lecturerSelfDevelopmentList.filter(function(param)
      {
        return (param.ref_academic_year.id.toString() == key1) && (param.ref_semester.id.toString() == key2)
      });
    }

    this.sortedLecturerSelfDevelopmentList = this.filteredLecturerSelfDevelopmentList.slice();
  }

  sortList(sort: Sort) {
    let data = this.lecturerSelfDevelopmentList;
    
    if (!sort.active || sort.direction === '') {
      this.sortedLecturerSelfDevelopmentList = data;
      return;
    }

    this.sortedLecturerSelfDevelopmentList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'user_academic_role': return compare(a.user.academic_role, b.user.academic_role, isAsc);
        case 'user_academic_major': return compare(a.user.academic_major, b.user.academic_major, isAsc);
        case 'user_code': return compare(a.user.code, b.user.code, isAsc);
        case 'user_name': return compare(a.user.name, b.user.name, isAsc);
        case 'value': return compare(a.value, b.value, isAsc);
        default: return 0;
      }
    });
  }

  getAllLecturers() {
    this.service.getAllLecturers().subscribe((response:UserInterface[])=>{
      this.userList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllSelfDevelopmentReferences() {
    this.service.getAllSelfDevelopmentReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.selfDevelopmentReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllTimeCategoryReferences() {
    this.service.getAllTimeCategoryReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.timeCategoryReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllAcademicYearReferences() {
    this.service.getAllAcademicYearReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.academicYearReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllSemesterReferences() {
    this.service.getAllSemesterReferences().subscribe((response:ReferenceVariableInterface[])=>{
      this.semesterReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  showModal(item?:LecturerSelfDevelopmentInterface)
  {
    this.hasSuccessfullySubmitted = false;
    this.errorMessage = null;

    if (item != null && item != undefined)
    {
      this.lecturer = item;
      this.lecturerID = this.lecturer.id;
      this.lecturerUserID = this.lecturer.user.id;
      this.lecturerRefSelfDevelopmentID = this.lecturer.ref_self_development.id;
      this.lecturerRefTimeCategoryID = this.lecturer.ref_time_category.id;
      this.lecturerRefAcademicYearID = this.lecturer.ref_academic_year.id;
      this.lecturerRefSemesterID = this.lecturer.ref_semester.id;
      this.lecturerActivityName = this.lecturer.activity_name;
    }
  }

  closeModal()
  {
    this.lecturer = null;
    this.lecturerID = null;
    this.lecturerUserID = null;
    this.lecturerRefSelfDevelopmentID = null;
    this.lecturerRefTimeCategoryID = null;
    this.lecturerRefAcademicYearID = null;
    this.lecturerRefSemesterID = null;
    this.lecturerActivityName = null;
  }

  submit() {
    var param = {
      user_id : this.lecturerUserID,
      ref_self_development_id : this.lecturerRefSelfDevelopmentID,
      ref_time_category_id : this.lecturerRefTimeCategoryID,
      ref_academic_year_id : this.lecturerRefAcademicYearID,
      ref_semester_id : this.lecturerRefSemesterID,
      activity_name : this.lecturerActivityName
    }

    this.isSubmitting = true;

    var id = null;
    if (this.lecturer != null && this.lecturer != undefined)
    {
      id = this.lecturerID;
    }
    
    this.service.postBKDData('lecturer_self_developments', param, id).subscribe((response:ResponseInterface)=>{
      if (response.message == 'ok')
      {
        this.hasSuccessfullySubmitted = true;
        this.getAllLecturerSelfDevelopments();
      }
      
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }

  delete() {
    this.isSubmitting = true;

    var id = this.lecturerID;
    
    this.service.deleteBKDData('lecturer_self_developments', id).subscribe((response:ResponseInterface)=>{
      if (response.message == 'ok')
      {
        this.hasSuccessfullySubmitted = true;
        this.getAllLecturerSelfDevelopments();
      }
      
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
