import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { SharedService } from 'src/app/shared.service';
import { UserInterface } from '../../interfaces/user';
import { AcademicRoleReferenceInterface } from '../../interfaces/academic-role-reference';
import { AccessRoleReferenceInterface } from '../../interfaces/access-role-reference';
import { ResponseInterface } from '../../interfaces/response';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  userName:string;
  isInitializing:boolean = true;

  userList:UserInterface[];
  sortedUserList:UserInterface[];
  academicRoleReferenceList:AcademicRoleReferenceInterface[];
  accessRoleReferenceList:AccessRoleReferenceInterface[];

  errorMessage:string = '';
  isSubmitting:boolean = false;
  hasSuccessfullySubmitted:boolean = false;
  
  resetPassword:boolean = true;
  showResetPasswordOption:boolean = true;
  showAcademicProperties:boolean = false;

  selectedUserID:number;
  selectedUserCode:string;
  selectedUserName:string;
  selectedUserEmail:string;
  selectedUserAccessRoleID:number;
  selectedUserAcademicRoleID:number;
  selectedUserAcademicMajorID:number;
  selectedUserAcademicPortion:number;
  selectedUserNIK:string;
  selectedUserNPWP:string;
  selectedUserPhone:string;
  selectedUserGender:number;
  selectedUserBirthDate:string;
  selectedUserBirthPlace:string;
  selectedUserAddressStreet:string;
  selectedUserAddressCity:string;
  selectedUserAddressProvince:string;
  selectedUserAddressPostalCode:string;
  selectedUserAddressCountry:string;

  constructor(private service:SharedService, private router:Router) { }

  ngOnInit(): void {
    this.checkCurrentUser();
  }

  checkCurrentUser() {
    this.service.getCurrentUser().subscribe((user:UserInterface)=>{
      this.userName = user.name;
      this.service.checkCurrentUserPermission(user);
      this.isInitializing = false;

      this.getAllUsers();
      this.getAllUserAcademicRoles();
      this.getAllUserAccessRoles();
    }, err => {
      this.isInitializing = false;
      this.router.navigate(['/login']);
    });
  }

  getAllUsers() {
    this.service.getAllUsers().subscribe((response:UserInterface[])=>{
      this.userList = response;
      this.sortedUserList = this.userList.slice();
    }, err => {
      alert(err.error.message);
    });
  }

  sortList(sort: Sort) {
    let data = this.userList;
    
    if (!sort.active || sort.direction === '') {
      this.sortedUserList = data;
      return;
    }

    this.sortedUserList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id': return compare(a.id, b.id, isAsc);
        case 'code': return compare(a.code, b.code, isAsc);
        case 'name': return compare(a.name, b.name, isAsc);
        case 'email': return compare(a.email, b.email, isAsc);
        case 'academic_role': return compare(a.academic_role, b.academic_role, isAsc);
        case 'academic_major': return compare(a.academic_major, b.academic_major, isAsc);
        case 'access_role': return compare(a.access_role, b.access_role, isAsc);
        default: return 0;
      }
    });
  }

  getAllUserAcademicRoles() {
    this.service.getAllUserAcademicRoles().subscribe((response:AcademicRoleReferenceInterface[])=>{
      this.academicRoleReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  getAllUserAccessRoles() {
    this.service.getAllUserAccessRoles().subscribe((response:AccessRoleReferenceInterface[])=>{
      this.accessRoleReferenceList = response;
    }, err => {
      alert(err.error.message);
    });
  }

  showModal(item?:UserInterface)
  {
    this.hasSuccessfullySubmitted = false;
    this.errorMessage = null;

    if (item != null && item != undefined)
    {
      this.selectedUserID = item.id;
      this.selectedUserCode = item.code;
      this.selectedUserName = item.name;
      this.selectedUserEmail = item.email;
      this.selectedUserAccessRoleID = item.ref_access_role_id;
      this.selectedUserAcademicRoleID = item.ref_academic_role_id;
      this.selectedUserAcademicMajorID = item.ref_academic_major_id;
      this.selectedUserAcademicPortion = item.academic_portion;
      this.selectedUserNIK = item.nik;
      this.selectedUserNPWP = item.npwp;
      this.selectedUserPhone = item.phone;
      this.selectedUserGender = item.gender;
      this.selectedUserBirthDate = item.birth_date;
      this.selectedUserBirthPlace = item.birth_place;
      this.selectedUserAddressStreet = item.address_street;
      this.selectedUserAddressCity = item.address_city;
      this.selectedUserAddressProvince = item.address_province;
      this.selectedUserAddressPostalCode = item.address_postal_code;
      this.selectedUserAddressCountry = item.address_country;

      if (this.selectedUserAcademicRoleID != null || this.selectedUserAcademicMajorID != null || this.selectedUserAcademicPortion != null)
      {
        this.showAcademicProperties = true;
      }
      else
      {
        this.showAcademicProperties = false;
      }

      this.resetPassword = false;
      this.showResetPasswordOption = true;
    }
    else
    {
      this.resetPassword = true;
      this.showResetPasswordOption = false;
    }
  }

  closeModal()
  {
    this.hasSuccessfullySubmitted = false;
    this.errorMessage = null;
    
    this.selectedUserID = null;
    this.selectedUserCode = null;
    this.selectedUserName = null;
    this.selectedUserEmail = null;
    this.selectedUserAccessRoleID = null;
    this.selectedUserAcademicRoleID = null;
    this.selectedUserAcademicMajorID = null;
    this.selectedUserAcademicPortion = null;
    this.selectedUserNIK = null;
    this.selectedUserNPWP = null;
    this.selectedUserPhone = null;
    this.selectedUserGender = null;
    this.selectedUserBirthDate = null;
    this.selectedUserBirthPlace = null;
    this.selectedUserAddressStreet = null;
    this.selectedUserAddressCity = null;
    this.selectedUserAddressProvince = null;
    this.selectedUserAddressPostalCode = null;
    this.selectedUserAddressCountry = null;

    this.resetPassword = true;
    this.showResetPasswordOption = false;
    this.showAcademicProperties = false;
  }

  submit() {
    var param = {
      code : this.selectedUserCode,
      name : this.selectedUserName,
      email : this.selectedUserEmail,
      ref_access_role_id : this.selectedUserAccessRoleID,
      nik : this.selectedUserNIK,
      npwp : this.selectedUserNPWP,
      phone : this.selectedUserPhone,
      gender : this.selectedUserGender,
      birth_date : this.selectedUserBirthDate,
      birth_place : this.selectedUserBirthPlace,
      address_street : this.selectedUserAddressStreet,
      address_city : this.selectedUserAddressCity,
      address_province : this.selectedUserAddressProvince,
      address_postal_code : this.selectedUserAddressPostalCode,
      address_country : this.selectedUserAddressCountry,
      ref_academic_role_id : null,
      ref_academic_major_id : null,
      academic_portion : null,
      reset_password : this.resetPassword
    }

    if (this.showAcademicProperties == true)
    {
      param.ref_academic_role_id = this.selectedUserAcademicRoleID;
      param.ref_academic_major_id = this.selectedUserAcademicMajorID;
      param.academic_portion = this.selectedUserAcademicPortion;
    }

    this.isSubmitting = true;
    
    this.service.postUser(param, this.selectedUserID).subscribe((response:ResponseInterface)=>{
      if (response.message == 'ok')
      {
        this.hasSuccessfullySubmitted = true;
        this.getAllUsers();
      }
      
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }

  delete() {
    this.isSubmitting = true;
    
    this.service.deleteUser(this.selectedUserID).subscribe((response:ResponseInterface)=>{
      if (response.message == 'ok')
      {
        this.hasSuccessfullySubmitted = true;
        this.getAllUsers();
      }
      
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
