<div class="row align-items-center" style="padding: 5px;">
    <div class="col-md-12 text-right">
        <div class="float-right" style="margin-right: 40px;">
            <h3 *ngIf="userName">Welcome, {{ userName }}</h3>
        </div>
    </div>
</div>

<div class="container py-3" *ngIf="isInitializing">
    <div class="sk-folding-cube">
        <div class="sk-cube1 sk-cube"></div>
        <div class="sk-cube2 sk-cube"></div>
        <div class="sk-cube4 sk-cube"></div>
        <div class="sk-cube3 sk-cube"></div>
    </div>
</div>

<div class="container py-3" *ngIf="userName">
	<div class="row">
		<div class="col-sm-12">
			<h1>Summaries</h1>
		</div>
    </div>
    <div class="row">
        <div class="col-sm-4">
            <select [(ngModel)]="selectedAcademicYearID" (ngModelChange)="getAllLecturerWorkloadSummaries()" class="form-control">
                <option value="" selected>Filter by academic Year</option>
                <option *ngFor="let academicYearReference of academicYearReferenceList" 
                    [value]="academicYearReference.id">
                    {{ academicYearReference.code }}
                </option>
            </select>
        </div>
        <div class="col-sm-4">
            <select [(ngModel)]="selectedSemesterID" (ngModelChange)="getAllLecturerWorkloadSummaries()" class="form-control">
                <option value="" selected>Filter by semester</option>
                <option *ngFor="let semesterReference of semesterReferenceList" 
                    [value]="semesterReference.id">
                    {{ semesterReference.description }}
                </option>
            </select>
        </div>
    </div>
    <br>
    <div class="row">
		<table class="table table-bordered" matSort (matSortChange)="sortList($event)">
			<thead>
				<tr>
					<th mat-sort-header="user_academic_role">Role</th>
					<th mat-sort-header="user_academic_major">Prodi</th>
					<th mat-sort-header="user_code">NIP</th>
					<th mat-sort-header="user_name">Nama</th>
					<th>Availability (%)</th>
					<th>Min</th>
					<th>Max</th>
					<th mat-sort-header="value">SKS</th>
					<th>Difference from Max</th>
					<th>Status</th>
                    <th style="text-align: center;">Action</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of sortedLecturerSummaryList">
                    <td>{{ item.academic_role }}</td>
                    <td>{{ item.academic_major }}</td>
                    <td>{{ item.code }}</td>
                    <td>{{ item.name }}</td>
                    <td style="text-align: center;">{{ item.academic_portion }}</td>
                    <td style="text-align: center;">{{ item.min_value }}</td>
                    <td style="text-align: center;">{{ item.max_value }}</td>
                    <td style="text-align: center;">{{ item.total_value }}</td>
                    <td style="text-align: center;">{{ item.deficit }}</td>
                    <td style="text-align: center;">{{ item.status }}</td>
                    <td style="width: 100px;">
                        <div class="row text-center" style="padding-left: 5px; padding-right: 5px;">
                            <div class="col" style="padding: 0px;">
                                <a [routerLink]="['/bkd/users', item.id, 'report']" target="_blank" class="btn btn-light">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
										<path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
										<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
									</svg>
								</a>
                            </div>
                        </div>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
</div>
