<div class="container py-5">
    <div class="row justify-content-center">
        <div class="layout-box">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Email</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" [(ngModel)]="userEmail">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Password</label>
                <div class="col-sm-9">
                    <div class="input-group mb-3">
                        <input type="password" class="form-control" [(ngModel)]="userPassword">
                        <div class="input-group-append">
                            <button (click)="showResetPasswordModal()" data-toggle="modal" data-target="#reset-password-modal" data-keyboard="false" class="btn btn-outline-secondary" type="button">
                                Forgot?
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <label style="color: red;">{{ authenticationErrorMessage }}</label>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <button (click)="authenticateUserInput()" [disabled]="isAuthenticating" class="btn btn-success" style="width: 100%;">
                        Login
                    </button>
                </div>
            </div>
            <div class="sk-folding-cube" *ngIf="isAuthenticating">
                <div class="sk-cube1 sk-cube"></div>
                <div class="sk-cube2 sk-cube"></div>
                <div class="sk-cube4 sk-cube"></div>
                <div class="sk-cube3 sk-cube"></div>
            </div>
        </div>
    </div>
</div>

<!-- Reset Password Modal -->
<div class="modal fade" id="reset-password-modal" data-backdrop="false" tabindex="-1" aria-labelledby="reset-password-modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="reset-password-modal-label">Reset Password</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="hasSuccessfullyRequestedResetPassword">
                    <div class="d-flex justify-content-center">
                        <svg id="successAnimation" class="animated" xmlns="http://www.w3.org/2000/svg" width="300" height="300" viewBox="0 0 70 70">
                            <path id="successAnimationResult" fill="#D8D8D8" d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"/>
                            <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" stroke-width="2" stroke-linecap="round" fill="green"/>
                            <polyline id="successAnimationCheck" stroke="#979797" stroke-width="2" points="23 34 34 43 47 27" fill="transparent"/>
                        </svg>
                    </div>
                    <br>
                    <div class="form-group row">
                      <div class="col-sm-12 text-center">
                        {{ resetPasswordSuccessMessage }}
                      </div>
                    </div>
                </div>
                <div *ngIf="!hasSuccessfullyRequestedResetPassword">
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Email</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" [(ngModel)]="userEmail">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label style="color: red;">{{ resetPasswordErrorMessage }}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <button (click)="requestResetPassword()" [disabled]="isRequestingResetPassword" class="btn btn-success" style="width: 100%;">
                                Submit
                            </button>
                        </div>
                    </div>
                    <div class="sk-folding-cube" *ngIf="isRequestingResetPassword">
                        <div class="sk-cube1 sk-cube"></div>
                        <div class="sk-cube2 sk-cube"></div>
                        <div class="sk-cube4 sk-cube"></div>
                        <div class="sk-cube3 sk-cube"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
