<div class="row align-items-center" style="padding: 5px;">
    <div class="col-md-12 text-right">
        <div class="float-right" style="margin-right: 40px;">
            <h3 *ngIf="userName">Welcome, {{ userName }}</h3>
        </div>
    </div>
</div>

<div class="container py-3" *ngIf="isInitializing">
    <div class="sk-folding-cube">
        <div class="sk-cube1 sk-cube"></div>
        <div class="sk-cube2 sk-cube"></div>
        <div class="sk-cube4 sk-cube"></div>
        <div class="sk-cube3 sk-cube"></div>
    </div>
</div>
