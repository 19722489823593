import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { UserInterface } from '../../../interfaces/user';
import { UserAdministrationInterface } from '../../../interfaces/hrd/user-administration-interfaces';
import { ResponseInterface } from '../../../interfaces/response';

@Component({
  selector: 'app-user-documents',
  templateUrl: './user-documents.component.html',
  styleUrls: ['./user-documents.component.css']
})
export class UserDocumentsComponent implements OnInit {

  @ViewChild('myInputFile') myInputFile:ElementRef;
  targetFile:File | null = null;

  userName:string;
  isInitializing:boolean = true;

  errorMessage:string = '';
  isSubmitting:boolean = false;
  hasSuccessfullySubmitted:boolean = false;

  selectedUserID:number;
  selectedUserName:string;
  userAdministration:UserAdministrationInterface;

  selectedDocumentTypeID:number;
  selectedDocumentID:number;

  constructor(
    private service:SharedService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.checkCurrentUser();
  }

  checkCurrentUser() {
    this.service.getCurrentUser().subscribe((user:UserInterface)=>{
      this.userName = user.name;
      this.service.checkCurrentUserPermission(user);
      this.isInitializing = false;

      this.selectedUserID = user.id;
      this.selectedUserName = user.name;

      this.getUserAdministration();
    }, err => {
      this.isInitializing = false;
      this.router.navigate(['/login']);
    });
  }

  getUserAdministration() {
    this.service.getUserAdministration(this.selectedUserID).subscribe((response:UserAdministrationInterface)=>{
      this.userAdministration = response;
    }, err => {
      alert(err.error.message);
    });
  }

  showUploadModal(docTypeID:number)
  {
    this.hasSuccessfullySubmitted = false;
    this.errorMessage = null;

    this.selectedDocumentTypeID = docTypeID;
  }

  showDeleteModal(docID:number)
  {
    this.hasSuccessfullySubmitted = false;
    this.errorMessage = null;

    this.selectedDocumentID = docID;
  }

  closeModal()
  {
    this.selectedDocumentTypeID = null;
    this.selectedDocumentID = null;
    this.targetFile = null;
  }

  handleFileInput(files: FileList) {
    this.targetFile = files.item(0);
  }

  upload() {
    if (this.targetFile == null || this.targetFile == undefined)
    {
      this.errorMessage = 'Please select file to be uploaded';
      return;
    }

    this.isSubmitting = true;

    const formData: FormData = new FormData();
    formData.append('upload', this.targetFile, this.targetFile.name);
    formData.append('user_id', this.selectedUserID.toString());
    formData.append('ref_upload_type_id', this.selectedDocumentTypeID.toString());
    
    this.service.postUserUpload(formData).subscribe((response:ResponseInterface)=>{
      this.getUserAdministration();
      this.myInputFile.nativeElement.value = '';
      this.hasSuccessfullySubmitted = true;
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }

  delete() {
    this.isSubmitting = true;
    
    this.service.deleteUserUpload(this.selectedDocumentID).subscribe((response:ResponseInterface)=>{
      this.getUserAdministration();
      this.myInputFile.nativeElement.value = '';
      this.hasSuccessfullySubmitted = true;
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }
}
