
import { NgModule } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Created tools
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedService } from './shared.service';

// Externals
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgApexchartsModule } from "ng-apexcharts";

// Materials
import { MatSortModule } from '@angular/material/sort';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

// Created components
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { ProfileComponent } from './components/profile/profile.component';
import { UsersComponent } from './components/users/users.component';
import { UserDocumentsComponent } from './components/hrd/user-documents/user-documents.component';
import { UserDocumentsDirectiveComponent } from './components/hrd/user-documents-directive/user-documents-directive.component';
import { ReferencesComponent } from './components/bkd/references/references.component';
import { SummariesComponent } from './components/bkd/summaries/summaries.component';
import { LecturerReportComponent } from './components/bkd/lecturer-report/lecturer-report.component';
import { LecturerReportDirectiveComponent } from './components/bkd/lecturer-report-directive/lecturer-report-directive.component';
import { LecturerStructuralPositionsComponent } from './components/bkd/lecturer-structural-positions/lecturer-structural-positions.component';
import { LecturerTeachingsComponent } from './components/bkd/lecturer-teachings/lecturer-teachings.component';
import { LecturerAcademicSupervisorsComponent } from './components/bkd/lecturer-academic-supervisors/lecturer-academic-supervisors.component';
import { LecturerThesisSupervisorsComponent } from './components/bkd/lecturer-thesis-supervisors/lecturer-thesis-supervisors.component';
import { LecturerStudentAffairSupervisorsComponent } from './components/bkd/lecturer-student-affair-supervisors/lecturer-student-affair-supervisors.component';
import { LecturerTeachingMaterialWritingsComponent } from './components/bkd/lecturer-teaching-material-writings/lecturer-teaching-material-writings.component';
import { LecturerResearchesComponent } from './components/bkd/lecturer-researches/lecturer-researches.component';
import { LecturerPublicServicesComponent } from './components/bkd/lecturer-public-services/lecturer-public-services.component';
import { LecturerSelfDevelopmentsComponent } from './components/bkd/lecturer-self-developments/lecturer-self-developments.component';
import { LecturerAcademicCommitteesComponent } from './components/bkd/lecturer-academic-committees/lecturer-academic-committees.component';
import { LecturerNonAcademicCommitteesComponent } from './components/bkd/lecturer-non-academic-committees/lecturer-non-academic-committees.component';
import { LecturerOtherWorksComponent } from './components/bkd/lecturer-other-works/lecturer-other-works.component';

const Materials = [
  MatSortModule,
  MatListModule,
  MatTabsModule,
  MatSidenavModule,
  MatButtonModule,
  MatProgressBarModule,
  MatAutocompleteModule
]

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}

@NgModule({
  declarations: [
    SafePipe,
    AppComponent,
    LoginComponent,
    HomeComponent,
    ProfileComponent,
    UsersComponent,
    UserDocumentsComponent,
    UserDocumentsDirectiveComponent,
    ReferencesComponent,
    SummariesComponent,
    LecturerReportComponent,
    LecturerReportDirectiveComponent,
    LecturerAcademicCommitteesComponent,
    LecturerAcademicSupervisorsComponent,
    LecturerNonAcademicCommitteesComponent,
    LecturerOtherWorksComponent,
    LecturerPublicServicesComponent,
    LecturerSelfDevelopmentsComponent,
    LecturerStructuralPositionsComponent,
    LecturerStudentAffairSupervisorsComponent,
    LecturerTeachingsComponent,
    LecturerTeachingMaterialWritingsComponent,
    LecturerThesisSupervisorsComponent,
    LecturerResearchesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    Ng2TelInputModule,
    NgApexchartsModule,
    Materials
  ],
  providers: [SharedService, DatePipe, DecimalPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
