import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { ProfileComponent } from './components/profile/profile.component';
import { UsersComponent } from './components/users/users.component';
import { UserDocumentsComponent } from './components/hrd/user-documents/user-documents.component';
import { UserDocumentsDirectiveComponent } from './components/hrd/user-documents-directive/user-documents-directive.component';
import { ReferencesComponent } from './components/bkd/references/references.component';
import { SummariesComponent } from './components/bkd/summaries/summaries.component';
import { LecturerReportComponent } from './components/bkd/lecturer-report/lecturer-report.component';
import { LecturerReportDirectiveComponent } from './components/bkd/lecturer-report-directive/lecturer-report-directive.component';
import { LecturerStructuralPositionsComponent } from './components/bkd/lecturer-structural-positions/lecturer-structural-positions.component';
import { LecturerTeachingsComponent } from './components/bkd/lecturer-teachings/lecturer-teachings.component';
import { LecturerAcademicSupervisorsComponent } from './components/bkd/lecturer-academic-supervisors/lecturer-academic-supervisors.component';
import { LecturerThesisSupervisorsComponent } from './components/bkd/lecturer-thesis-supervisors/lecturer-thesis-supervisors.component';
import { LecturerStudentAffairSupervisorsComponent } from './components/bkd/lecturer-student-affair-supervisors/lecturer-student-affair-supervisors.component';
import { LecturerTeachingMaterialWritingsComponent } from './components/bkd/lecturer-teaching-material-writings/lecturer-teaching-material-writings.component';
import { LecturerResearchesComponent } from './components/bkd/lecturer-researches/lecturer-researches.component';
import { LecturerPublicServicesComponent } from './components/bkd/lecturer-public-services/lecturer-public-services.component';
import { LecturerSelfDevelopmentsComponent } from './components/bkd/lecturer-self-developments/lecturer-self-developments.component';
import { LecturerAcademicCommitteesComponent } from './components/bkd/lecturer-academic-committees/lecturer-academic-committees.component';
import { LecturerNonAcademicCommitteesComponent } from './components/bkd/lecturer-non-academic-committees/lecturer-non-academic-committees.component';
import { LecturerOtherWorksComponent } from './components/bkd/lecturer-other-works/lecturer-other-works.component';

const routes: Routes = [
  { path : 'login', component : LoginComponent },
  { path : 'home', component : HomeComponent },
  { path : 'profile', component : ProfileComponent },
  { path : 'users', component : UsersComponent },
  { path : 'documents', component : UserDocumentsComponent },
  { path : 'users/:id/documents', component : UserDocumentsDirectiveComponent },
  { path : 'bkd/definisi', component : ReferencesComponent },
  { path : 'bkd/summaries', component : SummariesComponent },
  { path : 'bkd/report', component : LecturerReportComponent },
  { path : 'bkd/users/:id/report', component : LecturerReportDirectiveComponent },
  { path : 'bkd/jabatan-struktural', component : LecturerStructuralPositionsComponent },
  { path : 'bkd/pengajaran', component : LecturerTeachingsComponent },
  { path : 'bkd/bimbingan-akademik', component : LecturerAcademicSupervisorsComponent },
  { path : 'bkd/bimbingan-skripsi', component : LecturerThesisSupervisorsComponent },
  { path : 'bkd/bimbingan-kemahasiswaan', component : LecturerStudentAffairSupervisorsComponent },
  { path : 'bkd/penulisan-bahan-ajar', component : LecturerTeachingMaterialWritingsComponent },
  { path : 'bkd/penelitian', component : LecturerResearchesComponent },
  { path : 'bkd/pengabdian-masyarakat', component : LecturerPublicServicesComponent },
  { path : 'bkd/pengembangan-diri', component : LecturerSelfDevelopmentsComponent },
  { path : 'bkd/kepanitiaan-akademik', component : LecturerAcademicCommitteesComponent },
  { path : 'bkd/kepanitiaan-non-akademik', component : LecturerNonAcademicCommitteesComponent },
  { path : 'bkd/pekerjaan-lain', component : LecturerOtherWorksComponent },
  { path : '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
