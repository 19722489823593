<div class="row align-items-center" style="padding: 5px;">
    <div class="col-md-12 text-right">
        <div class="float-right" style="margin-right: 40px;">
            <h3 *ngIf="userName">Welcome, {{ userName }}</h3>
        </div>
    </div>
</div>

<div class="container py-3" *ngIf="isInitializing">
    <div class="sk-folding-cube">
        <div class="sk-cube1 sk-cube"></div>
        <div class="sk-cube2 sk-cube"></div>
        <div class="sk-cube4 sk-cube"></div>
        <div class="sk-cube3 sk-cube"></div>
    </div>
</div>

<div class="container py-3" *ngIf="userName">
	<div class="row">
		<div class="col">
			<h1>Users</h1>
		</div>
		<div class="col" style="text-align: right;">
            <button (click)="showModal()" type="button" class="btn btn-success" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                Add new
            </button>
		</div>
    </div>
    <div class="row">
		<table class="table table-bordered" matSort (matSortChange)="sortList($event)">
			<thead>
				<tr>
					<th mat-sort-header="id">ID</th>
					<th mat-sort-header="code">NIP</th>
					<th mat-sort-header="name">Nama</th>
					<th mat-sort-header="email">Email</th>
					<th mat-sort-header="access_role">Access</th>
					<th mat-sort-header="academic_role">Status Akademik</th>
					<th mat-sort-header="academic_major">Prodi</th>
					<th mat-sort-header="academic_Portion">Availability (%)</th>
                    <th style="text-align: center;">Action</th>
                </tr>
			</thead>
			<tbody>
                <tr *ngFor="let item of sortedUserList">
                    <td>{{ item.id }}</td>
                    <td>{{ item.code }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.access_role }}</td>
                    <td>{{ item.academic_role }}</td>
                    <td style="text-align: center;">{{ item.academic_major }}</td>
                    <td style="text-align: center;">{{ item.academic_portion }}</td>
                    <td style="width: 150px;">
                        <div class="row text-center" style="padding-left: 5px; padding-right: 5px;">
                            <div class="col" style="padding: 2px;">
                                <button (click)="showModal(item)" type="button" class="btn btn-light" data-toggle="modal" data-target="#form-modal" data-keyboard="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="col" style="padding: 2px;">
                                <a [routerLink]="['/users', item.id, 'documents']" target="_blank" class="btn btn-light">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-folder2-open" viewBox="0 0 16 16">
                                        <path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14V3.5zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5V6zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7H1.633z"/>
                                    </svg>
								</a>
                            </div>
                            <div class="col" style="padding: 2px;">
                                <button (click)="showModal(item)" type="button" class="btn btn-light" data-toggle="modal" data-target="#delete-modal" data-keyboard="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
			</tbody>
		</table>
    </div>
</div>

<!-- Create/Update Modal -->
<div class="modal fade" id="form-modal" data-backdrop="false" tabindex="-1" aria-labelledby="form-modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="form-modal-label">Form</h2>
                <button (click)="closeModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="hasSuccessfullySubmitted">
                    <div class="d-flex justify-content-center">
                        <svg id="successAnimation" class="animated" xmlns="http://www.w3.org/2000/svg" width="300" height="300" viewBox="0 0 70 70">
                            <path id="successAnimationResult" fill="#D8D8D8" d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"/>
                            <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" stroke-width="2" stroke-linecap="round" fill="green"/>
                            <polyline id="successAnimationCheck" stroke="#979797" stroke-width="2" points="23 34 34 43 47 27" fill="transparent"/>
                        </svg>
                    </div>
                    <br>
                    <div class="form-group row">
                      <div class="col-sm-12 text-center">
                        Successfully updated
                      </div>
                    </div>
                </div>
                <div *ngIf="!hasSuccessfullySubmitted">
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">NIP</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" [(ngModel)]="selectedUserCode">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Nama</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" [(ngModel)]="selectedUserName">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Email</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" [(ngModel)]="selectedUserEmail">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">NIK</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" [(ngModel)]="selectedUserNIK">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">NPWP</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" [(ngModel)]="selectedUserNPWP">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">No. HP</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" [(ngModel)]="selectedUserPhone">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Gender</label>
                        <div class="col-sm-10">
                            <select [(ngModel)]="selectedUserGender" class="form-control">
                                <option
                                    value="1" 
                                    [attr.selected]="selectedUserGender && selectedUserGender===1 ? true : null">
                                    Laki-laki
                                </option>
                                <option
                                    value="0" 
                                    [attr.selected]="selectedUserGender && selectedUserGender===0 ? true : null">
                                    Perempuan
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="col-form-label">Tempat</label>
                            <input type="text" class="form-control" [(ngModel)]="selectedUserBirthPlace">
                        </div>
                        <div class="col-sm-6">
                            <label class="col-form-label">Tanggal Lahir</label>
                            <input type="date" class="form-control" [(ngModel)]="selectedUserBirthDate">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Alamat</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" [(ngModel)]="selectedUserAddressStreet">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Hak Akses</label>
                        <div class="col-sm-10">
                            <select [(ngModel)]="selectedUserAccessRoleID" class="form-control">
                                <option *ngFor="let accessRoleReference of accessRoleReferenceList" 
                                    [value]="accessRoleReference.id" 
                                    [attr.selected]="selectedUserAccessRoleID && selectedUserAccessRoleID==accessRoleReference.id ? true : null">
                                    {{ accessRoleReference.role }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="showAcademicProperties">
                        <label class="col-sm-2 col-form-label">Status Akademik</label>
                        <div class="col-sm-10">
                            <select [(ngModel)]="selectedUserAcademicRoleID" class="form-control">
                                <option *ngFor="let academicRoleReference of academicRoleReferenceList" 
                                    [value]="academicRoleReference.id" 
                                    [attr.selected]="selectedUserAcademicRoleID && selectedUserAcademicRoleID==academicRoleReference.id ? true : null">
                                    {{ academicRoleReference.role }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="showAcademicProperties">
                        <label class="col-sm-2 col-form-label">Prodi</label>
                        <div class="col-sm-10">
                            <select [(ngModel)]="selectedUserAcademicMajorID" class="form-control">
                                <option 
                                    value="0" 
                                    [attr.selected]="selectedUserAcademicMajorID===0 ? true : null">
                                    RLAC
                                </option>
                                <option 
                                    value="1" 
                                    [attr.selected]="selectedUserAcademicMajorID==1 ? true : null">
                                    IBDA
                                </option>
                                <option 
                                    value="2" 
                                    [attr.selected]="selectedUserAcademicMajorID==2 ? true : null">
                                    IEE
                                </option>
                                <option 
                                    value="3" 
                                    [attr.selected]="selectedUserAcademicMajorID==3 ? true : null">
                                    SCCE
                                </option>
                                <option 
                                    value="4" 
                                    [attr.selected]="selectedUserAcademicMajorID==4 ? true : null">
                                    ASD
                                </option>
                                <option 
                                    value="5" 
                                    [attr.selected]="selectedUserAcademicMajorID==5 ? true : null">
                                    CFP
                                </option>
                                <option 
                                    value="6" 
                                    [attr.selected]="selectedUserAcademicMajorID==6 ? true : null">
                                    BMS
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="showAcademicProperties">
                        <label class="col-sm-2 col-form-label">Availability</label>
                        <div class="col-sm-10">
                            <div class="input-group">
                                <input type="number" class="form-control" [(ngModel)]="selectedUserAcademicPortion">
                                <div class="input-group-append">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-check text-right">
                        <label class="form-check-label" style="margin-right: 30px;">Has Academic Role</label>
                        <input class="form-check-input" type="checkbox" [(ngModel)]="showAcademicProperties" style="margin-top: 3px;">
                    </div>
                    <div class="form-check text-right" *ngIf="showResetPasswordOption">
                        <label class="form-check-label" style="margin-right: 30px;">Reset Password</label>
                        <input class="form-check-input" type="checkbox" [(ngModel)]="resetPassword" style="margin-top: 3px;">
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label style="color: red;">{{ errorMessage }}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <button (click)="submit()" [disabled]="isSubmitting" class="btn btn-success" style="width: 100%;">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Delete Modal -->
<div class="modal fade" id="delete-modal" data-backdrop="false" tabindex="-1" aria-labelledby="delete-modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="form-modal-label">Delete</h2>
                <button (click)="closeModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="hasSuccessfullySubmitted">
                    <div class="d-flex justify-content-center">
                        <svg id="successAnimation" class="animated" xmlns="http://www.w3.org/2000/svg" width="300" height="300" viewBox="0 0 70 70">
                            <path id="successAnimationResult" fill="#D8D8D8" d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"/>
                            <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" stroke-width="2" stroke-linecap="round" fill="green"/>
                            <polyline id="successAnimationCheck" stroke="#979797" stroke-width="2" points="23 34 34 43 47 27" fill="transparent"/>
                        </svg>
                    </div>
                    <br>
                    <div class="form-group row">
                      <div class="col-sm-12 text-center">
                        Successfully deleted
                      </div>
                    </div>
                </div>
                <div *ngIf="!hasSuccessfullySubmitted">
                    <div class="form-group row">
                        <div class="col-sm-12">
                            Are you sure to delete this item?
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label style="color: red;">{{ errorMessage }}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <button (click)="delete()" [disabled]="isSubmitting" class="btn btn-danger" style="width: 100%;">
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
