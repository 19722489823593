<div class="row align-items-center" style="padding: 5px;">
    <div class="col-md-12 text-right">
        <div class="float-right" style="margin-right: 40px;">
            <h3 *ngIf="userName">Welcome, {{ userName }}</h3>
        </div>
    </div>
</div>

<div class="container py-3" *ngIf="isInitializing">
    <div class="sk-folding-cube">
        <div class="sk-cube1 sk-cube"></div>
        <div class="sk-cube2 sk-cube"></div>
        <div class="sk-cube4 sk-cube"></div>
        <div class="sk-cube3 sk-cube"></div>
    </div>
</div>

<div class="container py-3" *ngIf="userName">
    <div class="row">
		<div class="col">
            <h1>
                {{ selectedUserName }}
            </h1>
		</div>
    </div>
    <br>
    <div class="row">
        <div *ngFor="let required_document of userAdministration.required_documents" class="col-4" style="margin-bottom: 20px; padding-left: 20px; padding-right: 20px;">
            <div class="row">
                <div class="col">
                    <h3 style="padding-top: 5px;">
                        {{ required_document.description }}
                    </h3>
                </div>
            </div>
            <div class="row">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>File Name</th>
                            <th style="text-align: center; width: 100px;">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let doc of required_document.documents">
                            <td>
                                {{ doc.description }}
                            </td>
                            <td>
                                <div class="row text-center" style="padding-left: 5px; padding-right: 5px;">
                                    <div class="col" style="padding: 0px;">
                                        <a href="{{ doc.url }}" target="_blank" class="btn btn-light" style="margin-bottom: 5px;">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
